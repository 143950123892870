<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-8 col-md-12">
				<card :headline="$t('google.posts.headline')">
					<!-- Description -->
					<p>{{$t('google.posts.description')}}</p>

					<template v-if="location && hasLoaded">
						<template v-if="isLocationVerificationPending">
							<span class="g-obs" v-html="$t('google.posts.pendingVerification')"></span>
						</template>

						<template v-else-if="!isLocationVerified">
							<span class="g-obs" v-html="$t('google.posts.notVerified')"></span>

							<div class="clearfix"></div>

							<a href="https://business.google.com" target="_blank">
								<button type="button" class="btn btn-primary verify-button">{{$t('google.posts.verify')}}</button>
							</a>
						</template>
					</template>

					<!-- Create post -->
					<div class="row option-cards-wrapper">
						<div class="col-md-6 col-sm-12">
							<router-link v-bind:to="isLocationVerified ? {name: 'frontend.googleAds.posts.create.event'} : {name: ''}">
								<card class="create event" v-bind:class="{unVerified: !isLocationVerified}">
									<span class="icon pe-7s-clock"></span>
									<h4>{{$t('google.posts.eventPostHeadline')}}</h4>

									<span class="divider"></span>

									<p>{{$t('google.posts.eventPostDescription')}}</p>

									<button type="button" class="btn btn-inverse">{{$t('google.posts.create')}}</button>
								</card>
							</router-link>
						</div>
						<div class="col-md-6 col-sm-12">
							<router-link v-bind:to="isLocationVerified ? {name: 'frontend.googleAds.posts.create.post'} : {name: ''}">
								<card class="create post" v-bind:class="{unVerified: !isLocationVerified}">
									<span class="icon pe-7s-news-paper"></span>
									<h4>{{$t('google.posts.standardPostHeadline')}}</h4>

									<span class="divider"></span>

									<p>{{$t('google.posts.standardPostDescription')}}</p>

									<button type="button" class="btn btn-inverse">{{$t('google.posts.create')}}</button>
								</card>
							</router-link>
						</div>
					</div>
				</card>

				<div class="posts" v-if="hasLoaded">
					<card v-if="posts.length" :headline="$t('google.posts.allPosts')">
						<div v-for="(post, index) in posts" :key="index">
							<div class="row item g-clearfix" >
								<transition name="slide-fade">
									<div v-show="failed && postId === post.id" class="col-xs-12">
										<warning v-on:hide="hide" type="alert">
											<p>{{$t('warnings.general.failed')}}</p>
										</warning>
									</div>
								</transition>

								<div v-if="post.media" class="col-md-4">
									<div
										class="post-img"
										:style="{ 'background-image': 'url(' + post.media[0].googleUrl + ')' }"
									></div>
								</div>

								<div class="col-md-8">
									<div class="row item g-clearfix">
										<div class="col-md-8 no-padding fixed-height post-headline-container">
											<!-- Type -->
											<span class="category">{{$t('google.posts.' + post.topicType.toLowerCase() + 'PostHeadline')}}</span>

											<!-- Title (Events only) -->
											<h5 v-if="post.event">{{post.event.title}}</h5>

											<!-- Date -->
											<p class="date" v-if="post.topicType.toLowerCase() === 'event'">
												{{ formatDate(post.event.schedule.startDate) | moment("Do MMM YYYY") }}
												<span class="fa fa-long-arrow-right fa-fw"></span>
												{{ formatDate(post.event.schedule.endDate) | moment("Do MMM YYYY") }}
											</p>

											<p class="date"	v-if="post.topicType.toLowerCase() === 'standard'">{{ post.createTime | moment("Do MMM YYYY") }}</p>
										</div>

										<div class="col-sm-12 hidden-md hidden-lg no-padding fixed-height post-content-container">
											<p>{{ post.summary | substr(0, 95) }}{{ (post.summary.length > 130) ? '...' :''}}</p>
										</div>

										<div v-if="false" class="col-md-4 col-sm-3 col-xs-6 no-padding fixed-height post-statistics-container">
											<p>
												<i class="fa fa-eye"></i>
												<template v-if="stats[post.id]">{{stats[post.id].views | numberFormat}}</template>

												<template v-else>
													<span class="fa fa-circle-o-notch fa-spin fa-fw"></span>
												</template>
											</p>

											<p>
												<i class="fa fa-mouse-pointer"></i>
												<template v-if="stats[post.id]">{{stats[post.id].clicks | numberFormat}}</template>

												<template v-else>
													<span class="fa fa-circle-o-notch fa-spin fa-fw"></span>
												</template>
											</p>
										</div>

										<div class="col-md-8 hidden-sm hidden-xs no-padding fixed-height post-content-container">
											<p>{{ post.summary | substr(0, 95) }}{{ (post.summary.length > 130) ? '...' :''}}</p>
										</div>

										<div class="col-md-4 col-xs-6 no-padding fixed-height post-actions-container">
											<router-link
												class="edit-link"
												v-if="post.topicType.toLowerCase() === 'event'"
												v-bind:to="{name: 'frontend.googleAds.posts.edit.event', params: {name: post.name}}">
												<i class="fa fa-fw fa-pencil-square-o"></i>
												{{$t('google.posts.edit')}}
											</router-link>

											<router-link
												class="edit-link"
												v-else
												v-bind:to="{name: 'frontend.googleAds.posts.edit.post', params: {name: post.name}}">
												<i class="fa fa-fw fa-pencil-square-o"></i>
												{{$t('google.posts.edit')}}
											</router-link>

											<br />

											<a @click="remove(post.id)">
												<i class="fa fa-fw fa-trash-o" v-if="postId !== post.id || failed"></i>
												<i class="fa fa-fw fa-circle-o-notch fa-spin" v-else></i>
												{{$t('google.posts.delete')}}
											</a>
										</div>
									</div>
								</div>
							</div>

							<hr />
						</div>
					</card>
				</div>

				<div v-else class="text-center">
					<br />
					<p>
						<span class="fa fa-circle-o-notch fa-spin fa-fw"></span>
						&nbsp; {{$t('google.posts.loadingPosts')}}&nbsp;
					</p>
				</div>

				<div v-if="!posts" class="text-center">
					<br />
					<p>{{$t('google.posts.noPosts')}}&nbsp;</p>
				</div>
			</div>

			<!-- Google MyBusiness card -->
			<div class="col-lg-4 hidden-md hidden-sm hidden-xs">
				<sidebar-preview></sidebar-preview>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_custom.scss";

.post-headline-container,
.post-statistics-container {
	padding-top: 30px;
}
.post-statistics-container {
	p {
		line-height: 12px;
	}
}
.post-actions-container {
	a {
		color: #000;
	}
	line-height: 10px;
}
.post-content-container {
	padding-right: 30px;
}

@media all and (max-width: 991px) {
	.post-headline-container,
	.post-content-container {
		padding-left: 20px;
	}
	.post-statistics-container {
		padding-left: 20px;
		padding-top: 10px;
	}
	.post-actions-container {
		padding-top: 10px;
	}
}

.create {
	color: #fff;
	height: 230px;
	margin-bottom: 0;
	margin-top: 20px;
	overflow: hidden;

	h4 {
		margin-top: 0;
		margin-bottom: 2px;
		font-weight: bold;
		font-size: 24px;
	}

	.divider {
		display: inline-block;
		height: 1px;
		width: 7%;
		margin-bottom: 10px;
	}

	.icon {
		position: absolute;
		color: white;
		opacity: 0.1;
		font-size: 300px;
		font-weight: 100;
	}

	&:hover {
		.btn {
			background-color: #fff;
		}
	}

	&.event {
		background-color: #3f8c9e;

		&:hover {
			.btn {
				color: #3f8c9e;
			}
		}

		.divider {
			border: 1px solid #44b3c6;
		}

		.icon {
			right: -95px;
			bottom: -110px;
		}
	}

	&.post {
		background-color: #3e9b9b;

		&:hover {
			.btn {
				color: #3e9b9b;
			}
		}

		.divider {
			border: 1px solid #34c1bd;
		}

		.icon {
			font-size: 250px;
			right: -5px;
			bottom: -50px;
		}
	}
}

.verify-button {
	margin-top: 5px;
}

.create,
.event {
	transition: opacity 1.2s ease;
}

.unVerified {
	opacity: 0.2;
	cursor: not-allowed;
	.btn {
		cursor: not-allowed;
	}
	&:hover {
		cursor: not-allowed;
		.btn {
			background-color: inherit !important;
			color: inherit !important;
		}
	}
}

$cardHeight: 200px;

.posts {
	hr {
		&:last-child {
			display: none;
		}
	}

	.item {
		height: $cardHeight;

		.post-img {
			height: $cardHeight;
			max-width: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.date {
			color: #888;
			font-size: 12px;
			margin-bottom: 30px;
			font-weight: 500;
		}

		.content-wrapper {
			display: flex;
			flex-direction: column;
			height: $cardHeight;

			> div {
				.category {
					display: block;
					font-weight: 300;
					text-transform: none;
					letter-spacing: 1px;
					margin-bottom: 10px;
				}

				.post-content {
					height: 85px;

					a {
						color: inherit;
						line-height: 24px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.create.event {
		margin-bottom: 20px;
	}

	.posts {
		.item {
			height: auto;

			.content-wrapper {
				display: block;
				height: auto;

				> div {
					.post-content {
						height: auto;

						.action-links {
							a {
								line-height: 30px;
							}
						}
					}
				}
			}

			.post-img {
				height: 130px;
			}
		}
	}
}
</style>

<script>
import SidebarPreview from "@/app/google-ads/components/SidebarPreview";
import Card from "@/app/shared/components/Card";
import Warning from "@/app/shared/global/warnings/Warning";
import MixpanelService from "@/services/mixpanel/MixpanelService";
import hasIntegration from "@/mixins/integrations/hasIntegration";
import * as LocationService from "@/services/google/LocationService";
import * as LocalPostService from "@/services/google/LocalPostService";

const moment = require("moment");

export default {
	mixins: [hasIntegration],
	data() {
		return {
			posts: [],
			stats: {},
			hasLoaded: false,
			location: null,
			failed: false,
			postId: null,
		};
	},

	mounted() {
		this.load();
		this.loadLocation();
	},

	computed: {
		isLocationVerified() {
			const location = this.location;

			if (!location) {
				return false;
			}

			return location.metadata.hasVoiceOfMerchant || false;
		},

		isLocationVerificationPending() {
			const location = this.location;

			if (!location) {
				return false;
			}

			return !location.metadata.hasVoiceOfMerchant || false;
		},
		getLocationName() {
			return this.hiIntegrations.find((integration) => integration.service.name === "Google Business Profile").data;
		},
	},

	methods: {
		load() {
			this.hasLoaded = false;

			const data = {
				locationName: this.getLocationName,
			};

			LocalPostService.findAll(data, (response) => {
				this.posts = response.data;

				if (!this.posts) {
					this.hasLoaded = true;
					return;
				}

				if (!this.posts.localPosts) {
					this.hasLoaded = true;
					return;
				}

				this.posts = this.posts.localPosts;

				//add localPost id to posts as property
				this.posts.forEach((post) => {
					const name = post.name;
					const splittet = name.split("/");
					const id = splittet[splittet.length - 1];

					post.id = id;
				});

				this.hasLoaded = true;

				//this.loadInsights(); //TODO: This will no longer exist in new api, and will therefore be removed.
			}, () => {
					this.failed = true;
					this.hasLoaded = true;
				}
			);
		},

		loadLocation() {
			const readMask =
				"title,name,phoneNumbers,categories,websiteUri,regularHours,specialHours,openInfo,storefrontAddress,metadata";
			const data = {
				locationName: this.getLocationName,
				readMask: readMask,
			};

			LocationService.location(data, (response) => {
				this.location = response.data;
				this.hasLoaded = true;
			});
		},

		loadInsights() {
			const posts = this.posts;
			const endTime = new Date().toISOString();

			for (const index in posts) {
				const post = posts[index];
				const id = post.id;
				const name = post.name;
				const startTime = this.dateTimeRange(post.createTime, endTime);

				const options = {
					locationName: this.getLocationName,
					localPostNames: name,
					basicRequest: {
						metricRequests: {
							metric: "ALL",
						},
						timeRange: {
							startTime: startTime,
							endTime: endTime,
						},
					},
				};

				LocalPostService.reportInsights(options, (response) => {
					const insights = response.data;

					this.$set(this.stats, id, this.parseInsights(insights));

					}, (error) => {
						const response = error.response;

						if (response.status === 404) {
							this.$set(this.stats, id, {
								views: 0,
								clicks: 0,
							});
							return;
						}

						eventHub.$emit("ajax-error", response);
					}
				);
			}
		},

		dateTimeRange(start, end) {
			const hoursBetween = moment(end).diff(moment(start), "h");

			if (hoursBetween >= 48) {
				return start;
			}

			return moment(end).subtract(2, "days").toISOString();
		},

		parseInsights(insights) {
			let values = {
				views: 0,
				clicks: 0,
			};

			if (!insights.localPostMetrics || !insights.localPostMetrics[0]) {
				return values;
			}

			const metrics = insights.localPostMetrics[0];

			if (!metrics.metricValues) {
				return values;
			}

			const metricValues = metrics.metricValues;

			metricValues.forEach((element) => {
				if (!element.metric || !element.totalValue) {
					return;
				}

				const totalValue = element.totalValue;

				switch (element.metric) {
					case "LOCAL_POST_VIEWS_SEARCH":
						values.views = parseInt(totalValue.value);
						break;
					case "LOCAL_POST_ACTIONS_CALL_TO_ACTION":
						values.clicks = parseInt(totalValue.value);
						break;
				}
			});

			return values;
		},

		remove(id) {
			this.postId = id;
			this.failed = false;

			const data = {
				name: this.getPostName(id),
				locationName: this.getLocationName,
			};

			LocalPostService.remove(
				data,
				() => {
					this.load();
				},
				() => {
					this.failed = true;
				}
			);
		},

		hide() {
			this.postId = null;
			this.failed = false;
		},

		getPostName(id) {
			const name = this.posts.find((post) => (post.id = id)).name;
			const arr = name.split("/");
			return arr[2] + "/" + arr[3] + "/" + arr[4] + "/" + arr[5];
		},
		formatDate(date) {
			return moment([date.year, date.month - 1, date.day]);
		},
	},

	components: {
		SidebarPreview,
		Card,
		Warning,
	},
};
</script>
