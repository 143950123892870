<template>
	<div class="views">
		<div v-for="key in Object.keys(views)"
			 :key="`view-${key}`"
			 :class="{ active: view === key }"
			 @click="select(key)"
			 class="view">
			<div class="icon">
				<i :class="views[key].icon" />
			</div>

			<span class="view-label" v-text="views[key].label" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.views {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	> div.view {
		display: flex;
		flex-direction: column;
		gap: 2px;
		color: #000;
		padding: 12px 20px;
		background-color: #eee;
		border-radius: 3px;

		cursor: pointer;
		user-select: none;

		&:hover {
			background-color: #ccc;
		}

		&.active {
			color: #fff;
			background-color: #f09ad6;
		}

		> div.icon {
			font-size: 18px;
		}

		> span.view-label {
			font-size: 12px;
			font-weight: 500;
		}
	}
}
</style>

<script>
export default {
	props: {
		view: {
		}
	},

	computed: {
		views() {
			const views = {
				default: {
					icon: 'far fa-list fa-fw',
					label: this.$t('google.ads.views.default')
				},

				efficiency: {
					icon: 'far fa-mouse-pointer fa-fw',
					label: this.$t('google.ads.views.efficiency')
				},

				video: {
					icon: 'far fa-video fa-fw',
					label: this.$t('google.ads.views.video')
				},

				display: {
					icon: 'far fa-desktop fa-fw',
					label: this.$t('google.ads.views.display')
				},

				ecommerce: {
					icon: 'far fa-shopping-cart fa-fw',
					label: this.$t('google.ads.views.ecommerce')
				},

				conversions: {
					icon: 'far fa-exchange fa-fw',
					label: this.$t('google.ads.views.conversions')
				},

				competition: {
					icon: 'far fa-users fa-fw',
					label: this.$t('google.ads.views.competition')
				}
			}

			return views
		}
	},

	methods: {
		select(view) {
			this.$emit('view', view)
		}
	}
}
</script>
