<template>
	<div v-if="keywords.length > 0"
		 class="keywords-wrapper">
		<div class="heading">{{ $t('google.ads.keywords.title') }}</div>

		<div class="controls">
			<div class="search">
				<div class="icon">
					<i class="far fa-search fa-fw" />
				</div>

				<input type="text"
					   v-model="query"
					   :placeholder="$t('google.ads.keywords.query')"
				/>
			</div>

			<div class="letters">
				<button type="button"
						@click="letter = null"
						:class="{ active: internalLetter === null }">
					{{ $t('google.ads.keywords.all') }}
				</button>

				<button v-for="l in letters"
						type="button"
						v-text="l"
						@click="letter = l"
						:class="{ active: internalLetter === l }"
				/>
			</div>
		</div>

		<div class="keywords">
			<div v-for="group in filteredGroups">
				<div class="letter"
					 v-text="group.letter"
				/>

				<div v-for="keyword in group.keywords"
					 :key="keyword"
					 class="keyword"
					 v-text="capitalize(keyword)"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.keywords-wrapper {
	border-top: 2px solid #eee;

	div.heading {
		color: #000;
		font-size: 16px;
		font-weight: 500;
		background-color: #fff;
		padding: 15px 25px;
	}

	div.controls {
		display: flex;
		align-items: center;
		gap: 25px;
		padding: 10px 25px;
		background-color: #f6f6f6;

		.text {
			color: #000;
			font-size: 16px;
			font-weight: 500;
		}

		.search {
			position: relative;
			border: 1px solid #ccc;
			border-radius: 6px;
			overflow: hidden;

			background-color: #fff;

			> div.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 0 0 8px;
				position: absolute;
				pointer-events: none;
				touch-action: none;
				top: 0;
				bottom: 0;
				left: 0;
				font-size: 16px;
			}

			input {
				height: 36px;
				border: 0;
				outline: 0;
				box-shadow: none;
				padding: 0 14px 0 36px;
				line-height: 100%;
				font-size: 14px;
				font-weight: 400;
			}
		}

		> div.letters {
			width: 100%;

			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap: 8px;

			> button {
				color: #737373;
				font-size: 15px;
				padding: 0;
				box-shadow: none;
				outline: 0;
				border: 0;
				background-color: transparent;

				&.active,
				&:hover {
					color: #000;
				}
			}
		}
	}

	> div.keywords {
		border-top: 1px solid #eee;
		padding: 15px 25px;

		column-count: 2;
		column-gap: 15px;
		max-width: 100%;

		> * {
			break-inside: avoid-column;
			margin-bottom: 10px;

			> div.letter {
				color: #000;
				font-size: 17px;
				font-weight: 500;
			}

			> div.keyword {
				color: #545454;
				font-size: 15px;
				font-weight: 400;
			}
		}
	}
}

@media (min-width: 768px) {
	.keywords-wrapper {
		> div.keywords {
			column-count: 2;
		}
	}
}

@media (min-width: 992px) {
	.keywords-wrapper {
		> div.keywords {
			column-count: 3;
		}
	}
}

@media (min-width: 1200px) {
	.keywords-wrapper {
		> div.keywords {
			column-count: 4;
		}
	}
}
</style>

<script>
export default {
	props: {
		keywords: {
			type: Array,
			required: false,
			default: () => ([])
		}
	},

	data: () => ({
		query: '',
		letter: null
	}),

	computed: {
		internalLetter() {
			return String(this.query || '').length === 0
				? this.letter
				: '..'
		},

		letters() {
			return Object.keys(this.keywords.reduce(
				(carry, item) => ({
					...carry,
					[this.initial(item)]: true
				}),
				{}
			)).sort(this.sort)
		},

		filteredGroups() {
			const query = String(this.query || '').toLocaleUpperCase()
			const letter = this.letter || null

			return JSON.parse(JSON.stringify(this.groupedKeywords))
				.map(group => {
					if (! query && !! letter && group.letter !== letter) {
						group.keywords = []
					}

					if (!! query) {
						group.keywords = group.keywords.filter(keyword => keyword.toLocaleUpperCase().includes(query))
					}

					return group
				})
				.filter(group => group.keywords.length > 0)
		},

		groupedKeywords() {
			const groups = this.keywords
				.reduce(
					(carry, item) => ({
						...carry,
						[this.initial(item)]: {
							...(carry[this.initial(item)] || []),
							[item]: true
						}
					}),
					{}
				)

			return Object.keys(groups)
				.sort(this.sort)
				.reduce(
				(carry, key) => ([
					...carry,
					{
						letter: key,
						keywords: Object.keys(groups[key]).sort(this.sort)
					}
				]),
				[]
			)
		}
	},

	methods: {
		initial(text) {
			return text.substring(0, 1).toUpperCase()
		},

		sort(a, b) {
			return String(a || '').localeCompare(String(b || ''))
		},

		capitalize(text) {
			return String(text || '').charAt(0).toLocaleUpperCase() + String(text || '').slice(1)
		}
	}
}
</script>
