<template>
	<div>
		<br/>

		<p class="g-p--max-width">{{ $t("google.overview.googleMyBusinessDescription") }}</p>

		<br/>

		<!-- Results -->
		<div class="row statistic-wrapper">
			<!-- Views in search -->
			<div
				class="col-lg-4 col-sm-12 no-padding statistic-container"
				v-for="(type, index) in types"
				:key="index">
				<div v-if="values[type] !== undefined">
					<div class="row no-padding">
						<div class="col-lg-4 statistic-container__box--left no-padding">
							<i :class="getIcon(type)" class="icon"/>
						</div>

						<div class="col-lg-8 statistic-container__box--right no-padding">
							<h3>{{ values[type] | numberFormat }}</h3>
							<span v-html="$tc('google.overview.' + type)"></span>
						</div>
					</div>
				</div>

				<div v-else class="statistic text-center">
					<h3 class="mock">123</h3>
					<span class="description mock">tekst</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_vars.scss";
@import "~@/assets/scss/_custom.scss";

.timespan {
	display: inline-block;
	color: #ffffff;
	background-color: #4096ee;
	border-radius: 0 5px 5px 0;
	margin-left: -30px;
	margin-bottom: 30px;
	padding: 5px 10px 5px 30px;
	font-size: 12px;
}

.statistic-wrapper {
	.icon {
		font-size: 40px;
	}

	.statistic-container {
		position: relative;
		padding: 5px 20px;
		min-height: 130px;

		.row {
			display: flex;

			.statistic-container__box--left {
				flex: 1;

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}

			.statistic-container__box--right {
				flex: 2;

				h3 {
					font-weight: 500;
					color: $googleMyBusiness;
					margin: 0 0 5px 0;
				}

				span {
					font-weight: 400;
					color: #808080;
					display: inline-block;
					line-height: 16px;
				}
			}
		}
	}
}
</style>

<script>
import * as LocationService from "@/services/google/LocationService";
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";
import Stars from "@/app/reviews/components/Stars";
import datepickerMixin from "@/app/datepicker/datepicker.mixin";
import hasIntegration from "@/mixins/integrations/hasIntegration";

import {mapGetters} from "vuex";

const moment = require("moment");

export default {
	mixins: [datepickerMixin, hasIntegration],
	data() {
		return {
			location: null,

			types: [
				"viewsInSearch",
				"viewsInMaps",
				"drivingDirections",
				"websiteClicks",
				"phoneClicks",
			],

			values: {},
		};
	},

	computed: {
		locationId() {
			if (!this.location) {
				return;
			}

			const location = this.location;

			return location.name.split("/").pop();
		},

		organizationColor() {
			return {background: $org("colors.standard.secondary.hex")};
		},
		getLocationName() {
			if (this.isDemo) {
				return ''
			}

			return this.hiIntegrations.find(
				(integration) =>
					integration.service.name === "Google Business Profile"
			).data;
		},
	},

	watch: {
		range() {
			this.loadResults((results) => {
				this.values = results;
			});
		},
	},

	mounted() {
		this.loadResults();
	},

	methods: {
		loadResults() {
			this.values = {};
			const end = moment(this.to);

			if (end.format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
				end.subtract(1, "day");
			}

			const startTime = new Date(this.from.toISOString());
			const endTime = end._d;
			const dailyRange = `&dailyRange__start_date__year=${startTime.getFullYear()}
			&dailyRange__start_date__month=${startTime.getMonth() + 1}
			&dailyRange__start_date__day=${startTime.getDate()}
			&dailyRange__end_date__year=${endTime.getFullYear()}
			&dailyRange__end_date__month=${endTime.getMonth() + 1}
			&dailyRange__end_date__day=${endTime.getDate()}
			`;

			const dailyMetrics = {
				WEBSITE_CLICKS: 'websiteClicks',
				CALL_CLICKS: 'phoneClicks',
				BUSINESS_IMPRESSIONS_DESKTOP_MAPS: 'viewsInMaps',
				BUSINESS_IMPRESSIONS_MOBILE_MAPS: 'viewsInMaps',
				BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: 'viewsInSearch',
				BUSINESS_IMPRESSIONS_MOBILE_SEARCH: 'viewsInSearch',
				BUSINESS_DIRECTION_REQUESTS: 'drivingDirections',
			};

			const values = {
				viewsInSearch: 0,
				websiteClicks: 0,
				phoneClicks: 0,
				viewsInMaps: 0,
				drivingDirections: 0,
			};

			for (const metric in dailyMetrics) {
				const data = {
					locationName: this.getLocationName,
					query: `?dailyMetric=${metric}${dailyRange}`,
				};

				LocationService.insights(data, response => {
					if (response.data?.success === false) {
						this.$swal({
							type: 'error',
							text: getTranslatedErrorMessage(response.data?.errorCode)
						});
					} else {
						response.data.timeSeries.datedValues.forEach((item) => {
							if (item.value) {
								values[dailyMetrics[metric]] += parseInt(item.value);
							}
						});
					}
				});
			}
			this.values = values;
		},

		getIcon(type) {
			let value = "";
			switch (type) {
				case "viewsInSearch":
					value = "icon pe-7s-search";
					break;
				case "viewsInMaps":
					value = "pe-7s-car";
					break;
				case "drivingDirections":
					value = "pe-7s-car";
					break;
				case "websiteClicks":
					value = "pe-7s-mouse";
					break;
				case "phoneClicks":
					value = "pe-7s-phone";
					break;
			}
			return value;
		},
	},

	components: {
		Stars,
	},
};
</script>
