<template>
    <div>
        <div v-if="hasLoaded">
            <table class="table table-striped table-condensed">
                <thead>
                    <tr>
                        <th class="table--width-large">{{$t('date.day')}}</th>
                        <th class="table--width-medium">{{$t('google.profile.hours.openTime')}}</th>
                        <th class="table--width-medium">{{$t('google.profile.hours.closeTime')}}</th>
                        <th class="table--width-small">{{$t('google.profile.hours.closed')}}</th>
                        <th class="table--width-small"></th>
                    </tr>
                </thead>

                <tbody>
                <!--Add newPeriod-->
                <tr>
                    <td>
                        <datepicker :mondayFirst="true" class="datepicker" input-class="form-control override-readonly" v-model="newPeriod.startDate" :format="customDateFormat"></datepicker>
                    </td>
                    <td>
                        <div class="form-group">
                            <time-dropdown v-model="newPeriod.openTime" :disabled="newPeriod.closed"></time-dropdown>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <time-dropdown v-model="newPeriod.closeTime" :disabled="newPeriod.closed"></time-dropdown>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input type="checkbox" v-model="newPeriod.closed" @change="toggleClosed(newPeriod)">
                        </div>
                    </td>
                    <!-- Action (ADD) -->
                    <td class="action special-hours__action">
                        <div class="form-group">
                            <div class="special-hours__action__btn special-hours__action__btn--add" @click="add()">
                                <i class="fa fa-plus"></i>
                            </div>
                        </div>
                    </td>
                </tr>
                <!--Existing periods-->
                <tr v-for="(period, key) in periods"  :class="{'danger': key in errors}" :key="key">
                    <td>
                        <datepicker
                            :mondayFirst="true"
                            class="datepicker"
                            input-class="form-control override-readonly"
                            v-model="period.startDate"
                            :format="customDateFormat">
                        </datepicker>
                    </td>
                    <td>
                        <div class="form-group">
                            <time-dropdown v-model="period.openTime" :disabled="period.closed"></time-dropdown>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <time-dropdown v-model="period.closeTime" :disabled="period.closed"></time-dropdown>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input type="checkbox" v-model="period.closed" @change="toggleClosed(period)">
                        </div>
                    </td>
                    <!-- Action (DELETE) -->
                     <td class="action special-hours__action">
                        <div class="form-group">
                            <div class="special-hours__action__btn special-hours__action__btn--delete" @click="remove(key)">
                                <i class="fa fa-trash"></i>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <br>
                <div class="text-danger" v-if="hasErrors">
                    <p v-for="(error, index) in errorMessages" :key="index" v-text="$t('google.profile.hours.errors.' + error)" />
                    <br>
                </div>

                 <div class="text-danger" v-if="hasServerErrors">
                    <p v-for="(error, index) in serverErrors" :key="index" v-text="error.message" />
                    <br>
                </div>
            <div class="row">
                <div class="col-md-12">
                    <p v-if="updated">{{$t('google.profile.hours.updated')}}</p>
                    <button @click="update()" :disabled="isSubmitting || hasErrors" class="btn btn-success btn-rounded">
                        <template v-if="isSubmitting">
                            <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                        </template>
                        {{$t('google.profile.save')}}
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-center">
                <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                {{$t('google.profile.loading')}}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';

    .special-hours__action {
        .special-hours__action__btn {
            cursor: pointer;
            &.special-hours__action__btn--add {
                color: $focusPositive;
            }
            &.special-hours__action__btn--delete {
                color: $focusAlert;
            }
        }
    }

    .table {
        thead {
            tr {
                th {
                    padding-left: 10px;
                    &.table--width-large {
                        width: 40%;
                    }

                    &.table--width-medium {
                        width: 25%;
                    }

                    &.table--width-small {
                        width: 5%;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0 10px 0 10px;
                    border-top: none;
                    width: calc(100% - 20px);
                }
            }
        }

        .form-group {
            margin: 10px 0 10px 0;
            input {
                margin-left: 10px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        select.form-control {
            -webkit-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
            padding-left: 6px;
        }

        .table {
            thead {
                tr {
                    th {
                        font-size: 8px;
                        text-align: left;
                        padding-left: 5px;
                        &.table--width-large {
                            width: 40%;
                        }

                        &.table--width-medium {
                            width: 25%;
                        }

                        &.table--width-small {
                            width: 5%;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 0 4px 0 4px;
                        border-top: none;
                    }
                }
            }
        }
    }

</style>

<script>
    import TimeDropdown from '@/app/google-ads/components/TimeDropdown'
    import Datepicker from 'vuejs-datepicker'
    import * as LocationService from '@/services/google/LocationService'
    import hasIntegration from '@/mixins/integrations/hasIntegration'

    const moment = require('moment')

    export default {
        mixins: [hasIntegration],
        data() {
            return {
                location: null,
                newPeriod: {
                    openTime: {
                        hours: 8,
                        minutes: 0
                    },
                    closeTime: {
                        hours: 16,
                        minutes: 0
                    },
                    startDate: moment().toDate(),
                    closed: false
                },
                periods: [],

                hasLoaded: false,
                updated: false,
                isSubmitting: false,
                serverErrors: [],
            }
        },

        computed: {
            locationId() {
                if (!this.location) {
                    return
                }

                const location = this.location

                return location.name.split('/').pop()
            },

            errors() {
                const errors = {}

                for (let key in this.periods) {

                    const period = this.periods[key]
                    let error = null

                    // Validate openTime not before closeTime

                    if (period.closeTime === null || period.openTime === null) {
                        continue
                    }

                    let openTime = moment({ h: period.openTime ? period.openTime.hours : 0, minute: period.openTime ? period.openTime.minutes : 0 })
                    let closeTime = moment({ h: period.closeTime ? period.closeTime.hours : 0, minute: period.closeTime ? period.closeTime.minutes : 0 })

                    if (this.parseToMinutes(openTime) > this.parseToMinutes(closeTime)) {
                        error = 'openTimeBeforeCloseTime'
                    }

                    // Validate startDate not before today
                    if (moment(period.startDate).isBefore(moment().hour(0).minute(0))) {
                        error = 'noDateBeforeToday'
                    }

                    // Only set one error at a time
                    if (error) {
                        this.$set(errors, key, error)
                    }
                }

                return errors
            },

            errorMessages() {
                if (!this.hasErrors) {
                    return []
                }

                const values = Object.values(this.errors)

                // Remove duplicate values

                return values.filter(function (element, index, values) {
                    return values.indexOf(element) === index
                })
            },

            hasErrors() {
                return Object.keys(this.errors).length !== 0
            },
            getLocationName(){
				if (this.isDemo) {
					return ''
				}

                return this.hiIntegrations.find(integration => integration.service.name === 'Google Business Profile').data
            },
            hasServerErrors() {
                return this.serverErrors.length > 0 ? true : false
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            formattedDate(period){
                const startDateString = period.startDate
                const startDate = moment(startDateString)
                const day = startDate.date()
                const month = startDate.month()
                const year = startDate.year()

                const formatted = {
                    day: day,
                    month: month,
                    year: year
                }

                return formatted
            },
            displayDate(date){
                return moment(date).format('YYYY-MM-DD')
            },
            load() {
                const readMask = 'specialHours'
                const data = {
                    locationName: this.getLocationName,
                    readMask: readMask
                }
                LocationService.location(data, (response) => {
                    this.location = response.data
                    const location = this.location

                    // Check for array existence
                    let loadedPeriods = (location.specialHours && Array.isArray(location.specialHours.specialHourPeriods)) ? location.specialHours.specialHourPeriods : []

                    if (!loadedPeriods.length) {
                        this.hasLoaded = true
                        return
                    }

                    loadedPeriods = loadedPeriods.filter(period => {
                        const today = new Date()
                        const startDate = new Date(period.startDate.year, period.startDate.month, period.startDate.day)

                        if(startDate.getTime() > today.getTime()) {
                            period.startDate = moment(startDate).format('YYYY-MM-DD')
                            return period
                        }
                    })

                    this.periods = loadedPeriods

                    this.hasLoaded = true
                })
            },

            update() {
                this.serverErrors = []
                this.$validator.validateAll().then(validatorResult => {

                    if (!validatorResult) {
                        return
                    }

                    this.isSubmitting = true
                    this.updated = false
                    let specialHourPeriods = []

                    this.periods.forEach(period => {
                        const formatted = this.formattedDate(period)
                        const periodClone = structuredClone(period)

                        periodClone.startDate = formatted

                        specialHourPeriods.push(periodClone)
                    })

                    const data = {
                        specialHours: {
                            specialHourPeriods: specialHourPeriods
                        }
                    }

                    const payload = {
                        locationName: this.getLocationName,
                        updateMask: 'specialHours',
                        data: data
                    }

                    LocationService.updateHours(payload, (response) => {
                        this.isSubmitting = false
                        this.updated = true
                    }, (error) => {
                        this.serverErrors.push({
                            code: error.code,
                            message: error.message
                        })
                        this.isSubmitting = false
                    })
                })
            },

            toggleClosed(period) {
                if (period.openTime || period.closeTime) {
                    period.openTime = null
                    period.closeTime = null
                    return
                }

                period.openTime = '08:00'
                period.closeTime = '16:00'
            },

            add() {
                this.periods.push(this.newPeriod)

                this.newPeriod =  {
                    openTime: {
                        hours: 8,
                        minutes: 0
                    },
                    closeTime: {
                        hours: 16,
                        minutes: 0
                    },
                    startDate: moment().toDate(),
                    closed: false
                }
            },

            remove(key) {
                this.$delete(this.periods, key)
            },

            customDateFormat(date) {
                return moment(date).format('Do MMM YYYY').toString()
            },

            randomPeriodId() {
               return Math.random().toString(36).substr(2, 5)
            },
            parseToMinutes(time){
                return time.minutes() + time.hours() * 60;
            },
        },

        components: {
            TimeDropdown,
            Datepicker
        }
    }
</script>
