<template>
    <check-for-missing-integrations :integrations="['Google Business Profile']">
        <landing-page slot="no-integrations" image="/images/integrations/cta-messages/profile.png" :headline="$t('google.overview.googleMyBusiness')" :description="$t('google.overview.googleMyBusinessDescription')">
            <integration name="Google Business Profile" service="my-business" styling="landscape" :border="true" logo="/images/onboarding/google-business.svg" :headline="$t('reviews.onboarding.googleMyBusiness.headline')" :description="$t('reviews.onboarding.googleMyBusiness.description')"></integration>
        </landing-page>

        <div class="google-profile">
            <div class="container-fluid">
                <!-- Location messages -->
                <div class="row" v-if="locationMessages.length">
                    <div class="col-md-12">
                        <warning  v-show="locationMessages && !hideMessage" v-on:hide="hideMessage = true" :type="locationMessages.includes('isPublished') ? 'error' : 'warning'">
                            <p><strong>{{$t('google.profile.state.messagesFromGoogle')}}</strong></p>

                            <ul>
                                <li v-for="(message, index) in locationMessages" :key="index">
                                    {{$t('google.profile.state.' + message)}}
                                </li>
                            </ul>

                            <span v-html="$t('google.profile.state.pressLinkToGoogleBusiness')"></span>
                        </warning>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-8">
                        <tab-navigation>
                            <ul>
                                <li>
                                    <a @click="navigate('overview')" :style="organizationColor('overview')" :class="{active: page === 'overview'}">
                                        {{$t('google.profile.tabs.overview')}}
                                    </a>
                                </li>
                                <li>
                                    <a @click="navigate('hours')" :style="organizationColor('hours')" :class="{active: page === 'hours'}">
                                        {{$t('google.profile.tabs.hours')}}
                                    </a>
                                </li>
                                <li>
                                    <a @click="navigate('information')" :style="organizationColor('information')" :class="{active: page === 'information'}">
                                        {{$t('google.profile.tabs.information')}}
                                    </a>
                                </li>

                            </ul>

                            <div class="clearfix"></div>

                            <hr />

                            <overview v-if="page === 'overview'"></overview>

                            <information v-else-if="page === 'information'"></information>

                            <div class="row" v-else-if="page === 'hours'">
                                <!-- Regular Opening Hours -->
                                <div class="col-lg-6 col-custom-breakpoint-12 col-md-12 col-sm-12 col-xs-12">
                                    <h4>{{$t('google.profile.hours.regularHours')}}</h4>
                                    <p class="opening-hours--if-empty"></p>
                                    <manage-regular-hours></manage-regular-hours>
                                </div>

                                <!-- Special Opening Hours -->
                                <div class="col-lg-6 col-custom-breakpoint-12 col-md-12 col-sm-12 col-xs-12">
                                    <h4>{{$t('google.profile.hours.specialHours')}}</h4>
                                    <p class="opening-hours--if-empty">{{$t('google.profile.hours.specialHoursHelptext')}}</p>
                                    <manage-special-hours></manage-special-hours>
                                </div>
                            </div>
                        </tab-navigation>
                    </div>

                    <div class="col-lg-4 hidden-md hidden-sm hidden-xs">
                        <sidebar-preview></sidebar-preview>
                    </div>
                </div>
            </div>
        </div>
    </check-for-missing-integrations>
</template>

<style lang="scss" scoped>
    .opening-hours--if-empty {
        &:empty {
            height: 21px;
        }
    }
    @media screen and (max-width: 1540px) {
        .col-custom-breakpoint-12 {
            width: 100%;
        }
    }
    @media screen and (max-width: 600px) {
        .opening-hours--if-empty {
            margin-bottom: 20px;
            &:empty {
                display: none;
            }
        }
    }
</style>

<script>
    import SidebarPreview from '@/app/google-ads/components/SidebarPreview'
    import ManageRegularHours from '@/app/google-ads/components/ManageRegularHours'
    import ManageSpecialHours from '@/app/google-ads/components/ManageSpecialHours'
    import TabNavigation from '@/app/layout/components/TabNavigation'
    import Information from '@/app/google-ads/components/Information'
    import Overview from '@/app/google-ads/components/Overview'
    import Warning from '@/app/shared/global/warnings/Warning'
    import * as LocationService from '@/services/google/LocationService'
    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import LandingPage from '@/app/onboarding/components/LandingPage'
    import Integration from '@/app/onboarding/components/integrations/Integration'
    import hasIntegration from '@/mixins/integrations/hasIntegration'

    export default {
        data() {
            return {
                location: null,
                hideMessage: false,
                page: 'overview'
            }
        },

        mixins: [hasIntegration],

        computed: {
            pageFromRoute() {
                return this.$route.params.page
            },

            locationMessages() {
                const location = this.location

                if (!location || !location.metadata) {
                    return []
                }

                const metadata = location.metadata

                const messages = [
                    'hasVoiceOfMerchant',
                    'hasGoogleUpdated',
                ]

                return messages.filter((type) => {
                    return metadata[type]
                })
            },
            getLocationName(){
                return this.hiIntegrations.find(integration => integration.service.name === 'Google Business Profile').data
            }
        },

        route: {
            canReuse: false
        },

        watch: {
            pageFromRoute() {
                this.checkRoute()
            }
        },

        mounted() {
            if (this.hasHealthyIntegration('Google Business Profile')) {
                this.load()
            }

            this.checkRoute()
        },

        methods: {
            load() {
                this.hasLoaded = false

                const readMask = 'title,name,phoneNumbers,categories,websiteUri,regularHours,specialHours,openInfo,storefrontAddress,metadata'
                const data = {
                    locationName: this.getLocationName,
                    readMask: readMask
                }

                LocationService.location(data, (response) => {
                    this.location = response.data
                    this.hasLoaded = true
                })
            },

            navigate(page) {
                this.$router.push({
                    name: 'frontend.googleAds.profile.page',
                    params: {
                        page: page
                    }
                })

                this.checkRoute()
            },

            organizationColor(trigger) {
                  return trigger === this.page ? {borderBottom: `2px solid ${$org('colors.standard.secondary.hex')}`} : null
            },

            checkRoute() {
                const page = this.pageFromRoute

                if (!page) {
                    return
                }

                this.page = page
            },
        },


        components: {
            CheckForMissingIntegrations,
            Integration,
            LandingPage,
            SidebarPreview,
            ManageRegularHours,
            ManageSpecialHours,
            TabNavigation,
            Information,
            Overview,
            Warning
        },
    }
</script>
