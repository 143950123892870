<template>
    <div>
        <div class="post">
            <template v-if="post.media">
                <div class="post-image-wrapper" v-if="post.media[0].sourceUrl">
                    <div v-if="post.media[0].sourceUrl" class="post-image" :style="{ 'background-image': 'url(' + post.media[0].sourceUrl + ')' }"></div>
                </div>

                <div class="post-image-wrapper" v-else-if="!post.media.state">
                    <div class="post-image" :style="{ 'background-image': 'url(' + post.media[0].googleUrl + ')' }"></div>
                </div>
            </template>

            <div class="post-content">
                <card :padding="false">
                    <template v-if="isEventPost">
                        <h5 class="title" v-if="post.eventTitle">{{ post.eventTitle }}</h5>

                        <p class="text-muted">
                            <template v-if="post.eventStart">
                                {{ post.eventStart.date | moment("Do MMM YYYY") }}
                            </template>

                            <template v-if="post.eventEnd">
                                <span class="fa fa-long-arrow-right fa-fw"></span>
                                {{ post.eventEnd.date | moment("Do MMM YYYY") }}
                            </template>
                        </p>
                    </template>

                    <p v-if="post.summary" style="white-space: pre-wrap;">{{ post.summary | substr(0,130) }}{{ (post.summary.length > 130) ? '...' :''}}</p>

                    <p class="time" v-if="post.updateTime">
                        {{ post.updateTime | moment("from", "now") }}
                    </p>

                    <p v-if="post.callToAction" class="action">{{$t('google.posts.actionTypes.' + post.callToAction.actionType.toLowerCase())}}</p>
                </card>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .post {
        width: 230px;
        display: inline-block;
        float: left;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
        border-top: 1px solid #dddddd;
        margin-bottom: 10px;

        .post-image {
            background: no-repeat center center;
            -webkit-background-size: cover;
            background-size: cover;
            height: 130px;
        }

        .post-content {
            > div {
                border-radius: 0;
                margin-bottom: 0;
            }

            p.time {
                color: grey;
            }

            p.action {
                color: #1a0dab;
            }

            h5.title {
                font-family: Arial, sans-serif;
                font-weight: 400;
                margin-bottom: 10px;
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'

    export default {
        props: {
            post: Object,
        },

        computed: {
            isEventPost() {
                const post = this.post
                const type = post.type

                return type ? type.toLowerCase() === 'event' : false
            },
        },

        components: {
            Card
        }
    }
</script>
