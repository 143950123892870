<template>
	<div v-if="isVisible"
		 class="display-placements">
		<div class="controls">
			<div class="search">
				<div class="icon">
					<i class="far fa-search fa-fw" />
				</div>

				<input type="text" v-model="query" />
			</div>
		</div>

		<div class="headlines">
			<div class="title">
				<span>{{ $t('google.ads.placements.title') }}</span>
			</div>

			<div class="metrics">
				<div class="metric"
					 @click="onSort('campaign', 'ASC')">
					<i class="far fa-bullhorn fa-fw" />
					<span>
						{{ $t('google.ads.placements.campaign') }}

						<i v-if="sort.key === 'campaign' && sort.direction === 'ASC'"
						   class="fas fa-sort-asc fa-fw"
						/>

						<i v-if="sort.key === 'campaign' && sort.direction === 'DESC'"
						   class="fas fa-sort-desc fa-fw"
						/>
					</span>
				</div>

				<div class="metric"
					 @click="onSort('group', 'ASC')">
					<i class="far fa-tag fa-fw" />
					<span>
						{{ $t('google.ads.placements.group') }}

						<i v-if="sort.key === 'group' && sort.direction === 'ASC'"
						   class="fas fa-sort-asc fa-fw"
						/>

						<i v-if="sort.key === 'group' && sort.direction === 'DESC'"
						   class="fas fa-sort-desc fa-fw"
						/>
					</span>
				</div>

				<div class="metric"
					 @click="onSort('clicks', 'DESC')">
					<i class="far fa-mouse-pointer fa-fw" />
					<span>
						{{ $t('google.ads.placements.clicks') }}

						<i v-if="sort.key === 'clicks' && sort.direction === 'ASC'"
						   class="fas fa-sort-asc fa-fw"
						/>

						<i v-if="sort.key === 'clicks' && sort.direction === 'DESC'"
						   class="fas fa-sort-desc fa-fw"
						/>
					</span>
				</div>

				<div class="metric"
					 @click="onSort('impressions', 'DESC')">
					<i class="far fa-eye fa-fw" />
					<span>
						{{ $t('google.ads.placements.impressions') }}

						<i v-if="sort.key === 'impressions' && sort.direction === 'ASC'"
						   class="fas fa-sort-asc fa-fw"
						/>

						<i v-if="sort.key === 'impressions' && sort.direction === 'DESC'"
						   class="fas fa-sort-desc fa-fw"
						/>
					</span>
				</div>

				<div class="metric"
					 @click="onSort('conversions', 'DESC')">
					<i class="far fa-filter fa-fw" />
					<span>
						{{ $t('google.ads.placements.conversions') }}

						<i v-if="sort.key === 'conversions' && sort.direction === 'ASC'"
						   class="fas fa-sort-asc fa-fw"
						/>

						<i v-if="sort.key === 'conversions' && sort.direction === 'DESC'"
						   class="fas fa-sort-desc fa-fw"
						/>
					</span>
				</div>

				<div class="metric"
					 @click="onSort('cpc', 'ASC')">
					<i class="far fa-coins fa-fw" />
					<span>
						{{ $t('google.ads.placements.cpc') }}

						<i v-if="sort.key === 'cpc' && sort.direction === 'ASC'"
						   class="fas fa-sort-asc fa-fw"
						/>

						<i v-if="sort.key === 'cpc' && sort.direction === 'DESC'"
						   class="fas fa-sort-desc fa-fw"
						/>
					</span>
				</div>
			</div>
		</div>

		<div class="table-rows">
			<div v-for="(placement, index) in items"
				 :key="`placement-${index}`"
				 class="table-row">
				<div class="title">
					<div class="name">{{ placement.groupPlacementView.displayName }}</div>

					<div class="tags">
						<div class="tag">
							<i class="far fa-tag" />
							<div>
								<span>{{ $t('google.ads.placements.network') }}</span>
								<span>{{ ucfirst(placement.segments.adNetworkType) }}</span>
							</div>
						</div>

						<div class="tag">
							<i v-if="ucfirst(placement.groupPlacementView.placementType).includes('Mobile')" class="far fa-mobile" />
							<i v-else class="far fa-globe" />

							<div>
								<span>{{ $t('google.ads.placements.placement') }}</span>
								<span>{{ ucfirst(placement.groupPlacementView.placementType) }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="metrics">
					<div class="metric">
						<div class="metric-label">
							<i class="far fa-bullhorn fa-fw" />
							<span>{{ $t('google.ads.placements.campaign') }}</span>
						</div>

						<span>{{ placement.campaign.name }}</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-tag fa-fw" />
							<span>{{ $t('google.ads.placements.group') }}</span>
						</div>

						<span>{{ placement.adGroup.name }}</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-mouse-pointer fa-fw" />
							<span>{{ $t('google.ads.placements.clicks') }}</span>
						</div>

						<span v-if="!! placement.metrics.clicks">{{ placement.metrics.clicks }}</span>
						<span v-else>-</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-eye fa-fw" />
							<span>{{ $t('google.ads.placements.impressions') }}</span>
						</div>

						<span v-if="!! placement.metrics.impressions">{{ placement.metrics.impressions }}</span>
						<span v-else>-</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-filter fa-fw" />
							<span>{{ $t('google.ads.placements.conversions') }}</span>
						</div>

						<span v-if="!! placement.metrics.conversions">{{ placement.metrics.conversions }}</span>
						<span v-else>-</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-coins fa-fw" />
							<span>{{ $t('google.ads.placements.cpc') }}</span>
						</div>

						<span v-if="!! placement.metrics.averageCpc">{{ formatCurrency(placement.metrics.averageCpc, placement.customer.currencyCode) }}</span>
						<span v-else>-</span>
					</div>
				</div>
			</div>

			<div v-if="placements === null"
				 v-for="n in limit"
				 :key="`placeholder-${n}`"
				 class="table-row">
				<div class="title mock">Diplay name goes here</div>

				<div class="metrics">
					<div class="metric">
						<div class="metric-label">
							<i class="far fa-bullhorn fa-fw" />
							<span>{{ $t('google.ads.placements.campaign') }}</span>
						</div>

						<span class="mock">
							<span>abc</span>
						</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-tag fa-fw" />
							<span>{{ $t('google.ads.placements.group') }}</span>
						</div>

						<span class="mock">
							<span>abc</span>
						</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-mouse-pointer fa-fw" />
							<span>{{ $t('google.ads.placements.clicks') }}</span>
						</div>

						<span class="mock">
							<span>abc</span>
						</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-eye fa-fw" />
							<span>{{ $t('google.ads.placements.impressions') }}</span>
						</div>

						<span class="mock">
							<span>abc</span>
						</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-filter fa-fw" />
							<span>{{ $t('google.ads.placements.conversions') }}</span>
						</div>

						<span class="mock">
							<span>abc</span>
						</span>
					</div>

					<div class="metric">
						<div class="metric-label">
							<i class="far fa-coins fa-fw" />
							<span>{{ $t('google.ads.placements.cpc') }}</span>
						</div>

						<span class="mock">
							<span>abc</span>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div v-if="placements !== null"
			 class="table-pagination">
			<div class="showing">
				{{ $t('google.ads.placements.showing', { from: (page - 1) * limit, to: page * limit < rawItems.length ? page * limit : rawItems.length, total: rawItems.length }) }}
			</div>
			<div class="controls">
				<button type="button"
						:disabled="page === 1"
						@click="previous">
					<i class="far fa-long-arrow-left fa-fw" />
				</button>

				<button type="button"
						:disabled="page >= pages"
						@click="next">
					<i class="far fa-long-arrow-right fa-fw" />
				</button>
			</div>
		</div>
	</div>
</template>

<script>

import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import {mapGetters} from "vuex";

const GoogleAdsService = require('@/services/google-ads/GoogleAdsService')

export default {
	mixins: [
		datepickerMixin
	],

	data: () => ({
		query: '',
		placements: null,

		limit: 12,
		page: 1,

		sort: {
			key: 'clicks',
			direction: 'DESC'
		}
	}),

	computed: {
		pages() {
			return Math.ceil(this.rawItems.length / this.limit)
		},

		rawItems() {
			const sort = this.sort
			const query = this.searchString(this.query)

			return (this.placements || [])
				.sort((a, b) => {
					let compare = null

					switch (sort.key) {
						case 'campaign':
							compare = a.campaign.name > b.campaign.name
							break

						case 'group':
							compare = a.adGroup.name > b.adGroup.name
							break

						case 'clicks':
							compare = Number(a.metrics.clicks || 0) > Number(b.metrics.clicks || 0)
							break

						case 'impressions':
							compare = Number(a.metrics.impressions || 0) > Number(b.metrics.impressions || 0)
							break

						case 'conversions':
							compare = Number(a.metrics.conversions || 0) > Number(b.metrics.conversions || 0)
							break

						case 'cpc':
							compare = Number(a.metrics.averageCpc || 0) > Number(b.metrics.averageCpc || 0)
							break
					}

					return compare === (sort.direction === 'ASC')
						? 1
						: -1
				})
				.filter(item => {
					if (query.length === 0) {
						return true
					}

					return this.searchString(item.groupPlacementView.displayName).includes(query) ||
						this.searchString(item.campaign.name).includes(query) ||
						this.searchString(item.adGroup.name).includes(query)
				})
		},

		items() {
			return this.rawItems.slice(this.limit * (this.page - 1), (this.limit * (this.page - 1)) + this.limit)
		},

		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		...mapGetters('ghost', {
			isDemo: 'isDemo',
		}),

		integrationId() {
			if (this.isDemo) {
				return 'demo-id'
			}

			const integrations = this.customer.integrations

			const match = integrations.find(integration => integration.service.name === "Google Ads")

			return !! match
				? match.id
				: null
		},

		isVisible() {
			return this.placements === null || (this.placements || []).length > 0
		}
	},

	watch: {
		sort() {
			this.page = 1
		},

		query() {
			this.page = 1
		},

		from() {
			this.page = 1
			this.onLoad()
		}
	},

	mounted() {
		this.onLoad()
	},

	methods: {
		searchString(input) {
			return String(input || '').toLocaleLowerCase()
		},

		onLoad() {
			GoogleAdsService.fetchPlacements(
				this.from,
				this.to,
				this.integrationId,
				({ data }) => {
					this.placements = data || null
				},
				(error) => {
				}
			)
		},

		formatCurrency(value, currency) {
			const formatter = new Intl.NumberFormat(this.$i18n.locale, {
				currency,
				style: 'currency',
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			})

			return formatter.format(value / 1000000)
		},

		onSort(key, fallback) {
			const opposite = this.sort.direction === 'ASC'
				? 'DESC'
				: 'ASC'

			const direction = this.sort.key === key
				? opposite
				: fallback

			this.sort = {
				key,
				direction
			}
		},

		previous() {
			this.page--
		},

		next() {
			this.page++
		},

		ucfirst(input) {
			return input
				.replace('_', ' ')
				.replace('-', ' ')
				.split(' ')
				.map(part => {
					return part.charAt(0).toLocaleUpperCase() + part.slice(1).toLocaleLowerCase()
				})
				.join(' ')
		}
	}
}
</script>

<style lang="scss" scoped>
.display-placements {
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	border-radius: 8px;
	background-color: #fff;

	.title {
		width: 400px;
	}

	> div.controls {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		min-height: 60px;
		gap: 20px;
		padding: 15px 25px;
		border-bottom: 1px solid #eee;

		color: #1f1f1f;
		font-size: 16px;
		font-weight: 600;

		> .search {
			position: relative;
			border: 1px solid #ccc;
			border-radius: 6px;
			overflow: hidden;

			> div.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 0 0 8px;
				position: absolute;
				pointer-events: none;
				touch-action: none;
				top: 0;
				bottom: 0;
				left: 0;
				font-size: 16px;
			}

			input {
				height: 36px;
				border: 0;
				outline: 0;
				box-shadow: none;
				padding: 0 14px 0 36px;
				line-height: 100%;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}

	> div.headlines {
		display: flex;
		align-items: center;
		min-height: 60px;
		gap: 20px;
		border-bottom: 1px solid #eee;

		color: #1f1f1f;
		font-size: 16px;
		font-weight: 600;

		> div.title {
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 15px 25px;

			color: #000;
			font-weight: 500;
		}

		> div.metrics {
			display: flex;
			flex: 1;

			> div.metric {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 4px;

				padding: 15px 25px;
				min-height: 100%;

				flex: 1 1 0;
				width: 0;

				> i {
					font-size: 20px;
				}

				font-size: 12px;
				font-weight: 500;

				&:hover {
					cursor: pointer;
					user-select: none;
					background-color: #f5f5f5;
				}
			}
		}
	}

	> div.table-rows {
		display: flex;
		flex-direction: column;

		> div.table-row {
			display: flex;
			gap: 20px;
			border-bottom: 1px solid #eee;

			&:nth-child(odd) {
				background-color: #fafafa;
			}

			&:hover {
				cursor: pointer;
				user-select: none;
				background-color: #eee;
			}

			.title {
				padding: 15px 25px;

				display: flex;
				flex-direction: column;
				gap: 8px;

				> div.name {
					font-size: 15px;
					font-weight: 500;
				}

				> div.tags {
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					gap: 30px;

					> div.tag {
						display: flex;
						align-items: center;
						gap: 10px;

						line-height: 115%;

						> i {
							font-size: 16px;
						}

						> div {
							display: flex;
							flex-direction: column;

							> span:nth-child(1) {
								color: #5b5b5b;
								font-size: 12px;
							}

							> span:nth-child(2) {
								color: #000;
								font-size: 13px;
							}
						}
					}
				}
			}

			> div.metrics {
				display: flex;
				flex: 1;

				> div.metric {
					flex: 1 1 0;
					width: 0;

					padding: 15px 25px;
					min-height: 100%;

					display: flex;
					align-items: center;
					justify-content: center;

					.metric-label {
						display: none;
					}

					&:nth-child(odd) {
						background-color: rgba(0, 0, 0, .02);
					}

					> span {
						padding: 4px 4px;
						line-height: 100%;
						font-size: 13px;
						font-weight: 500;
						text-align: center;
					}
				}
			}
		}
	}

	.table-pagination {
		padding: 20px 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 25px;

		.showing {
			font-size: 13px;
			color: #7b7b7b;
		}

		.controls {
			display: flex;
			align-items: center;
			gap: 6px;

			button {
				cursor: pointer;
				width: 35px;
				height: 35px;
				min-width: 35px;
				min-height: 35px;
				border-radius: 50%;
				background-color: #eee;
				border: 0;

				&:disabled {
					cursor: not-allowed;
					opacity: .5;
				}
			}
		}
	}
}


@media(max-width: 750px) {
	div.display-placements {
		.title {
			width: 100%;
		}

		> div.headlines {
			> div.title {
				padding: 15px !important;
			}

			> div.metrics {
				display: none;
			}
		}

		> div.table-rows {
			> div.table-row {
				display: flex;
				flex-direction: column;
				padding: 0 !important;

				> div.title {
					flex: 0;
					padding: 15px 15px 0 15px !important;
				}

				> div.metrics {
					flex: 0;

					display: flex;
					flex-direction: column;
					align-items: flex-start;

					> div.metric {
						display: flex;
						width: 100%;
						justify-content: space-between;
						border-bottom: 1px solid #eee;
						padding: 20px 15px !important;

						&:last-child {
							border-bottom: 0;
						}
					}

					.metric-label {
						display: flex !important;
						align-items: center;
						gap: 6px;

						font-weight: 500;

						i {
							color: #274fd2;
						}
					}
				}
			}
		}
	}
}
</style>
