<template>
	<div class="sidebar-preview">
		<div class="header-images">
			<div class="col-xs-6">
				<div v-if="media && getCover" class="additional" :style="{'background-image': 'url(' + getCover + ')'}" ></div>
				<div v-else class="additional" ></div>
			</div>

			<div class="col-xs-6">
				<div v-if="map" class="map" :style="`background-image: url('${map}');`"></div>
				<div v-else class="map"></div>

				<div v-if="streetView" class="streetview" :style="`background-image: url('${streetView}');`" ></div>
				<div v-else class="streetview"></div>
			</div>
		</div>

		<div class="clearfix"></div>

		<div class="google-card">
			<card :padding="false">
				<div class="heading">
					<span v-if="hasLoadedLocation">{{ location.title }}</span>
					<span class="mock" v-else>firmanavn</span>
				</div>

				<div class="buttons">
					<span class="btn btn-sm btn-default">{{$t('google.profile.website')}}</span>
					<span class="btn btn-sm btn-default">{{$t('google.profile.directions')}}</span>
				</div>

				<div class="rating" v-if="reviews && reviews.totalReviews !== 0">
					<p v-if="hasLoadedLocationReviews">
                        <span class="stars">
                            {{ Math.ceil(reviews.averageRating) }}&nbsp;
                            <i class="fa fa-star active" v-for="n in Math.ceil(reviews.averageRating)" :key="n"></i>
                            <template v-if="Math.ceil(reviews.averageRating) !== 0">
                                <i class="fa fa-star" v-for="(n,index) in 5 - Math.ceil(reviews.averageRating)" :key="index"></i>
                            </template>
                        </span>

						<span class="reviews">{{ reviews.totalReviewCount }} {{$t('google.profile.googleReviews')}}</span>
					</p>

					<p v-else>
                        <span class="stars">
                            <span class="mock">0.0</span>
                        </span>

						<span class="reviews mock">00 Google-anmeldelser</span>
					</p>
				</div>

				<div class="branch-location">
                    <span v-if="hasLoadedLocation">
                        <template v-if="location.categories.primaryCategory">
                            {{ location.categories.primaryCategory.displayName }},
                        </template>
                        <template v-if="location.storefrontAddress.locality">
                        {{ location.storefrontAddress.locality }}
                        </template>
                    </span>
					<span class="mock" v-else>branche, Bynavn</span>
				</div>

				<div class="clearfix"></div>

				<hr>

				<div class="details">
					<p class="address" v-if="location && location.storefrontAddress">
						<b>{{$t('google.profile.address')}}:</b>

						<span v-if="location.storefrontAddress">
                            {{ street }}, {{ location.storefrontAddress.postalCode }} {{ location.storefrontAddress.locality }}
                        </span>

						<span class="mock" v-else>Gadenavn, 0000 By</span>
					</p>

					<p class="hours">
						<b>{{$t('google.profile.hours.openTime')}}:</b>

						<template v-if="hasLoadedLocation">
							<template v-if="location.openInfo">
                                <span v-if="location.openInfo.status === 'OPEN'">
                                    {{$t('google.profile.hours.open')}}
                                </span>

								<span v-else>{{$t('google.profile.hours.closed')}}</span>
							</template>

							<span v-else>{{$t('google.profile.hours.unknown')}}</span>
						</template>

						<span v-else class="mock">mocktext</span>
					</p>

					<p class="phone"><b>{{$t('google.profile.phone')}}:</b>
						<span v-if="hasLoadedLocation">
                            {{ location.phoneNumbers.primaryPhone }}
                        </span>

						<span class="mock" v-else>00000000</span>
					</p>
				</div>

				<hr>

				<template v-if="hasLoadedPosts">
					<template v-if="hasLocalPosts">
						<div class="posts">
							<div class="posts-header">
								<template v-if="getProfile">
									<div class="logo" v-if="getProfile">
										<img class="img-responsive" :src="getProfile" alt="">
									</div>
								</template>
								<div class="text">

									<span v-if="hasLoadedLocation">{{location.title}}</span>
									<span class="mock" v-else>navn</span>

									<br>

									<span>{{$t('google.profile.onGoogle')}}</span>
								</div>
								<div class="clearfix"></div>
							</div>

							<div class="posts-container" :style="{'margin-left': ((offset * cardWidth) * -1) + 'px'}">
								<post class="sidebar-post" :key="i" v-for="(post, i) in posts.localPosts" :post="post"></post>
								<div class="clearfix"></div>
							</div>

							<div class="scroll-button left" v-show="posts.length > 1 && offset !== 0" @click="slideLeft">
								<i class="fa fa-chevron-left"></i>
							</div>

							<div class="scroll-button right" v-show="posts.length > 1 && offset !== posts.length - 1" @click="slideRight">
								<i class="fa fa-chevron-right"></i>
							</div>
						</div>

						<hr>
					</template>
				</template>

				<template v-else>
					<div class="text-center">
						<span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
						Indlæser indlæg
					</div>

					<hr>
				</template>

				<p class="preview-answer-questions">
					<strong>{{$t('google.profile.knowThisPlace')}}</strong>
					<span>{{$t('google.profile.answerQuickQuestions')}}</span>
				</p>
			</card>
		</div>

	</div>
</template>

<style lang="scss" scoped>
.sidebar-preview {
	border-radius: 3px;
	overflow: hidden;

	.header-images {
		border-bottom: 0.5px solid white;

		.col-xs-6 {
			padding: 0;
			overflow: hidden;
		}

		.additional {
			width: 100%;
			height: 160px;
			background: linear-gradient(#ffffff, #cccccc);
			background-position: center center;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
		}

		.map {
			width: 100%;
			height: 80px;
			background-size: cover;
			background-color: #d9d9d9;
			background-position: center center;
		}

		.streetview {
			width: 100%;
			height: 80px;
			background-size: cover;
			background-color: #e6e6e6;
			background-position: center center;
		}
	}

	.google-card {
		font-family: Arial, sans-serif;

		> div {
			border-radius: 0;
		}

		.heading {
			span {
				font-size: 30px;
				i {
					color: lightgrey;
					font-size: 20px;
				}
			}
		}

		.rating {
			p {
				margin: 0;
			}
			.stars {
				margin-right: 10px;
				color: #e7711b;

				.fa {
					width: 14px;
					color: lightgrey;

					&.active {
						color: #e7711b;
					}
				}
			}
			.reviews {
				color: #1a0dab;
			}
		}

		.branch-location {
			color: grey;
		}

		.buttons {
			span {
				background-color: #f5f5f8;
				border: 1px solid rgba(0,0,0,0.1);
				color: #444;
				font-weight: 700;
				font-size: 11px;
				padding: 6px 12px;
				opacity: 1;
				border-radius: 2px;
				margin-top: 6px;
				margin-bottom: 10px;

				&:hover {
					color: #444;
				}
			}
		}

		.details {
			p {
				margin-bottom: 5px;

				&.hours {
					span {
						color: #1a0dab;
					}
				}

				&.phone {
					span {
						color: #1a0dab;
					}
				}
			}
		}

		.posts {
			overflow: hidden;
			position: relative;
			margin-right: -15px;
			margin-left: -15px;

			.posts-header {
				margin: 0 15px 10px 15px;

				.logo {
					display: inline-block;
					float: left;
					margin-right: 10px;
					max-width: 50px;
				}
				.text {
					display: inline-block;
					float: left;

					span {
						font-size: 12px;
						color: grey;
					}
				}

			}

			.posts-container {
				display: flex;
				overflow: scroll;
				transition: all 0.5s ease-in-out;

				.sidebar-post {
					margin-left: 15px;
				}
			}

			.scroll-button {
				cursor: pointer;
				height: 72px;
				position: absolute;
				display: block;
				visibility: inherit;
				width: 36px;
				z-index: 1;
				bottom: 0;
				margin-bottom: auto;
				margin-top: auto;
				outline: none;
				opacity: 0.8;
				top: 0;
				background-color: rgba(229, 229, 229, .8);

				i {
					bottom: 0;
					display: block;
					margin: auto 0;
					position: absolute !important;
					top: 0;

					height: 20px;
					font-size: 20px;
				}

				&.left {
					border-bottom-right-radius: 36px;
					border-top-right-radius: 36px;
					box-shadow: 1px 0 2px rgba(0, 0, 0, 0.5);

					left: 0;

					i {
						left: 0;
					}
				}

				&.right {
					border-bottom-left-radius: 36px;
					border-top-left-radius: 36px;
					box-shadow: -1px 0 2px rgba(0, 0, 0, 0.5);

					right: 0;

					i {
						right: 0;
					}
				}
			}
		}

		.preview-answer-questions span {
			color: #1a0dab;
		}
	}
}
</style>

<script>
import Card from '@/app/shared/components/Card'
import Post from '@/app/google-ads/components/Post'

import * as LocalPostService from '@/services/google/LocalPostService'
import * as LocationService from '@/services/google/LocationService'
import hasIntegration from '@/mixins/integrations/hasIntegration'

const moment = require('moment')

export default {
	mixins: [hasIntegration],
	data() {
		return {
			loadedLocation: null,
			posts: null,
			media: null,
			reviews: null,

			hasLoadedPosts: false,
			hasLoadedLocation: false,
			hasLoadedLocationMedia: false,
			hasLoadedLocationReviews: false,

			cardWidth: 245,
			offset: 0
		}
	},

	props: {
		preview: Object
	},

	mounted() {
		this.loadLocation()
		this.loadPosts()
	},

	computed: {
		hasLocalPosts() {
			if(!this.posts){
				return false
			}
			if(!this.posts.localPosts){
				return false
			}
			return this.posts.localPosts.length > 0
		},
		street() {
			if (!this.location) {
				return null
			}

			const location = this.location

			if (!location.storefrontAddress) {
				return null
			}

			const address = location.storefrontAddress

			return address.addressLines.join(', ')
		},

		location() {
			if (this.preview) {
				this.hasLoadedLocation = true

				return JSON.parse(JSON.stringify(this.preview))
			}
			return this.loadedLocation
		},

		map() {
			if (!this.street) {
				return
			}

			let street = this.street

			street = street.replace(/, /g, ',')
			street = street.replace(/ /g, '+')

			return 'https://maps.googleapis.com/maps/api/staticmap?center=' + street + '&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C' + street + '&zoom=14&size=300x100&maptype=roadmap&key=AIzaSyBH40KzdWNdeOYxaeo-UipFTp5dmo-q2KQ'
		},

		streetView() {
			if (!this.street) {
				return
			}

			let street = this.street

			street = street.replace(/, /g, ',')
			street = street.replace(/ /g, '+')

			return 'https://maps.googleapis.com/maps/api/streetview?size=300x100&location=' + street + '&fov=90&key=AIzaSyCmv-8YeqJWgNPph9757ImRL74-Q75hOVU'
		},
		getLocationName(){
			if (this.isDemo) {
				return ''
			}

			return this.hiIntegrations.find(integration => integration.service.name === 'Google Business Profile').data
		},
		getCover(){
			if(!this.media){
				return null
			}
			return this.media.mediaItems.filter(item => item.locationAssociation.category === 'COVER')[0].googleUrl
		},
		getProfile(){
			if(!this.media){
				return null
			}
			return this.media.mediaItems.filter(item => item.locationAssociation.category === 'PROFILE')[0].googleUrl
		}
	},

	watch: {
		posts() {
			this.offset = 0
		},
	},

	methods: {
		slideRight() {
			if ( this.offset >= (this.posts.length - 1) ) {
				return
			}

			this.offset++
		},

		slideLeft() {
			if (this.offset <= 0) {
				return
			}

			this.offset--
		},

		loadLocation() {
			this.hasLoadedLocation = false

			const readMask = 'title,name,phoneNumbers,categories,websiteUri,regularHours,specialHours,openInfo,storefrontAddress,metadata'
			const data = {
				locationName: this.getLocationName,
				readMask: readMask
			}

			LocationService.location(data, (response) => {
				this.loadedLocation = response.data

				this.loadLocationMedia()
				this.loadLocationReviews()

				this.hasLoadedLocation = true
			})
		},

		loadLocationMedia() {
			this.hasLoadedLocationMedia = false

			const data = {
				locationName: this.getLocationName,
			}

			LocationService.media(data, response => {
				this.media = response.data
				this.hasLoadedLocationMedia = true
			}, (error) => {
				console.log("error: ", error)
			})
		},

		loadLocationReviews() {
			this.hasLoadedLocationReviews = false

			const data = {
				locationName: this.getLocationName,
			}

			LocationService.reviews(data, (response) => {
				this.reviews = response.data
				this.hasLoadedLocationReviews = true
			})
		},

		loadPosts() {
			this.hasLoadedPosts = false

			const data = {
				locationName: this.getLocationName,
			}

			LocalPostService.findAll(data, (response) => {
				this.posts = response.data
				this.hasLoadedPosts = true
			})
		},

		getLocationId() {
			if (!this.location) {
				return
			}
			return this.location.name.split('/').pop()
		},
	},

	components: {
		Card,
		Post
	}
}
</script>
