<template>
    <select ref="timeSelect" class="form-control" v-model="selected" @change="change" :disabled="disabled">
        <option v-for="(period, index) in periods" :value="period" v-text="period" :key="index" />
    </select>
</template>

<script>
    const moment = require('moment')

    export default {
        data() {
            return {
                selected: this.formatValue(this.value),
            }
        },

        computed: {
            periods() {
                const periods = []

                let start = moment().set({
                    hour: 0,
                    minute: 0
                })

                let end = moment(start).add(1, 'day')

                for (let time = moment(start); time.isBefore(end); time.add(30, 'minutes')) {
                    periods.push(time.format('HH:mm'))
                }

                periods.push('24:00')

                return periods
            },
        },

        props: {
            value: {},
            disabled: Boolean
        },

        watch: {
            selected (changed) {
                this.selected = changed
            }
        },

        methods: {
            change() {           
                const time = moment(this.selected, 'HH:mm')
                const valueChanged = {
                    hours: time.hours(),
                    minutes: time.minutes()
                }
                
                this.$emit('input', valueChanged)
            },

            formatValue(value){
                let hours = 0
                let minutes = 0
                let time = moment({ hour: hours, minute: minutes }).format('HH:mm')

                if(!value){
                    return time
                }

                if(!value.hours){
                    hours = 0
                }

                if(!value.minutes){
                    minutes = 0
                }

                hours = value.hours
                minutes = value.minutes

                time = moment({ hour: hours, minute: minutes }).format('HH:mm')

                return time
            }
        },
    }
</script>