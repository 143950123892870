<template>
    <check-for-missing-integrations :integrations="['Google Business Profile']">
        <landing-page slot="no-integrations" image="/images/integrations/cta-messages/google-local-posts.png" :headline="$t('google.posts.headline')" :description="$t('google.posts.description')">
            <integration name="Google Business Profile" service="google-business-profile" styling="landscape" :border="true" logo="/images/onboarding/google-business.svg" :headline="$t('reviews.onboarding.googleMyBusiness.headline')" :description="$t('reviews.onboarding.googleMyBusiness.description')"></integration>
        </landing-page>

        <div class="posts-page">
            <router-view></router-view>
        </div>
    </check-for-missing-integrations>
</template>

<script>
    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import LandingPage from '@/app/onboarding/components/LandingPage'
    import Integration from '@/app/onboarding/components/integrations/Integration'

    export default {
        components: {
            CheckForMissingIntegrations,
            LandingPage,
            Integration
        }
    }
</script>
