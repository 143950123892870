<template>
    <div>
        <div v-if="hasLoaded">

            <table class="table table-striped table-condensed">
                <thead>
                <tr>
                    <th>{{$t('date.day')}}</th>
                    <th>{{$t('google.profile.hours.openTime')}}</th>
                    <th>{{$t('google.profile.hours.closeTime')}}</th>
                    <th>{{$t('google.profile.hours.closed')}}</th>
                </tr>
                </thead>

                <tbody>
                    <tr v-for="(period, index) in periods" :class="{'danger': errors.includes(period.openDay)}" :key="index">
                        <td class="name">{{$t('weekdays.' + period.openDay.toLowerCase())}}</td>

                        <td>
                            <div class="form-group">
                                <time-dropdown v-model="period.openTime" :disabled="period.openTime === null"></time-dropdown>
                            </div>
                        </td>

                        <td>
                            <div class="form-group">
                                <time-dropdown v-model="period.closeTime" :disabled="period.closeTime === null"></time-dropdown>
                            </div>
                        </td>

                        <td>
                            <div class="form-group">
                                <input type="checkbox" :checked="isClosed(period)" @click="toggleClosed(period)">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <div class="text-danger" v-if="hasErrors">
                {{$t('google.profile.hours.errors.openTimeBeforeCloseTime')}}
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <p v-if="updated">{{$t('google.profile.hours.updated')}}</p>

                    <button @click="update" :disabled="isSubmitting || hasErrors" class="btn btn-success btn-rounded">
                        <template v-if="isSubmitting">
                            <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                        </template>
                        {{$t('google.profile.save')}}
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-center">
                <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                {{$t('google.profile.loading')}}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';

    .table {
        thead {
            tr {
                th {
                    padding-left: 10px;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0 10px 0 10px;
                    border-top: none;
                    width: calc(100% - 20px);
                }
            }
        }

        .form-group {
            margin: 10px 0 10px 0;
            input {
                margin-left: 10px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        select.form-control {
            -webkit-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
            padding-left: 6px;
        }

        .table {
            thead {
                tr {
                    th {
                        font-size: 8px;
                        padding-left: 5px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 0 4px 0 4px;
                        border-top: none;
                    }
                }
            }
        }
    }
</style>

<script>
    import Warning from '@/app/shared/global/warnings/Warning'
    import TimeDropdown from '@/app/google-ads/components/TimeDropdown'
    import * as LocationService from '@/services/google/LocationService'
    import hasIntegration from '@/mixins/integrations/hasIntegration'

    const moment = require('moment')

    export default {
        data() {
            return {
                periods: {
                    MONDAY: {openDay: 'MONDAY', closeDay: 'MONDAY', closeTime: null, openTime: null},
                    TUESDAY: {openDay: 'TUESDAY', closeDay: 'TUESDAY', closeTime: null, openTime: null},
                    WEDNESDAY: {openDay: 'WEDNESDAY', closeDay: 'WEDNESDAY', closeTime: null, openTime: null},
                    THURSDAY: {openDay: 'THURSDAY', closeDay: 'THURSDAY', closeTime: null, openTime: null},
                    FRIDAY: {openDay: 'FRIDAY', closeDay: 'FRIDAY', closeTime: null, openTime: null},
                    SATURDAY: {openDay: 'SATURDAY', closeDay: 'SATURDAY', closeTime: null, openTime: null},
                    SUNDAY: {openDay: 'SUNDAY', closeDay: 'SUNDAY', closeTime: null, openTime: null},
                },

                hasLoaded: false,
                updated: false,
                location: null,
                isSubmitting: false
            }
        },

        mixins: [hasIntegration],

        computed: {
            locationId() {
                if (!this.location) {
                    return
                }

                const location = this.location

                return location.name.split('/').pop()
            },

            errors() {
                const errors = []

                for (let key in this.periods) {

                    const period = this.periods[key]

                    if (period.closeTime === null || period.openTime === null) {
                        continue
                    }

                    let openTime = moment({ h: period.openTime.hours, minute: period.openTime.minutes })
                    let closeTime = moment({ h: period.closeTime.hours, minute: period.closeTime.minutes })

                    if(this.parseToMinutes(openTime) > this.parseToMinutes(closeTime)) {
                        errors.push(key)
                    }
                }

                return errors
            },

            hasErrors() {
                return this.errors.length !== 0
            },
            getLocationName(){
				if (this.isDemo) {
					return ''
				}

                return this.hiIntegrations.find(integration => integration.service.name === 'Google Business Profile').data
            },
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                const readMask = 'regularHours'
                const data = {
                    locationName: this.getLocationName,
                    readMask: readMask
                }
                LocationService.location(data, (response) => {
                    this.location = response.data
                    const location = this.location

                    const regularHours = (location.regularHours && Array.isArray(location.regularHours.periods)) ? location.regularHours.periods : []

                    regularHours.forEach(period => {
                        if (this.periods[period.openDay] === undefined) {
                            return
                        }

                        this.periods[period.openDay] = period
                    })

                    this.hasLoaded = true
                })
            },

            update() {
                if (this.hasErrors) {
                    return
                }

                this.isSubmitting = true

                const periods = this.periods

                const data = {
                    regularHours: {
                        periods: []
                    }
                }

                for (const key of Object.keys(periods)) {
                    const period = periods[key]

                    if (!period.openTime || !period.closeTime) {
                        continue
                    }

                    data.regularHours.periods.push(period)
                }

                if (!data.regularHours.periods.length) {
                    this.isSubmitting = false
                    return
                }

                const payload = {
                    locationName: this.getLocationName,
                    updateMask: 'regularHours',
                    data:  data
                }

                LocationService.updateHours(payload, (response) => {
                    this.isSubmitting = false
                    this.updated = true
                })
            },

            toggleClosed(period) {
                if (period.openTime || period.closeTime) {
                    period.openTime = null
                    period.closeTime = null
                    return
                }

                period.openTime = '08:00'
                period.closeTime = '16:00'
            },

            isClosed(period) {
                return period.openTime === null || period.closeTime === null
            },

            parseToMinutes(time){
                return time.minutes() + time.hours() * 60;
            }
        },

        components: {
            Warning,
            TimeDropdown
        }
    }
</script>
