<template>
	<div class="ad-insights">
		<div v-if="images.length > 0" class="inner">
			<img v-for="(image, index) in images"
				 :key="`image-${index}`"
				 :src="image"
				 @click="enlarge = index"
			/>
		</div>

		<modern-modal v-if="enlarge !== null"
					  @close="enlarge = null">
			<div slot="body">
				<img class="image" :src="images[enlarge]" />
			</div>
		</modern-modal>
	</div>
</template>

<style lang="scss" scoped>
img.image {
	display: block;
	max-width: 100%;
	max-height: 500px;
	margin: 20px auto;
}

.ad-insights {
	> div.inner {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		padding: 20px;

		> img {
			width: 100px;
			min-width: 100px;
			height: 100px;
			min-height: 100px;
			border-radius: 8px;
			border: 2px solid #fff;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
			object-fit: cover;

			opacity: .8;

			user-select: none;
			cursor: pointer;

			transition: all .25s ease-in-out;

			&:hover {
				opacity: 1;
			}
		}
	}
}
</style>

<script>
import {mapGetters} from "vuex";
import ModernModal from "@/app/layout/components/ModernModal";

const GoogleAdsService = require('@/services/google-ads/GoogleAdsService')

export default {
	components: {ModernModal},
	props: {
		ad: {
			type: Object,
			required: true
		},
	},

	data: () => ({
		insights: null,
		enlarge: null
	}),

	computed: {
		images() {
			const insights = this.insights || []

			return insights.reduce(
				(carry, item) => {
					const image = (((item.adGroupAd || {}).ad || {}).imageAd || {}).imageUrl || null

					if (!! image) {
						carry.push(image)
					}

					return carry
				},
				[]
			)
		},

		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		...mapGetters('ghost', {
			isDemo: 'isDemo',
		}),

		...mapGetters('datepicker', {
			from: 'getFrom',
			to: 'getTo'
		}),

		integrationId() {
			if (this.isDemo) {
				return 'demo-id'
			}

			const integrations = this.customer.integrations

			const match = integrations.find(integration => integration.service.name === "Google Ads")

			return !! match
				? match.id
				: null
		}
	},

	mounted() {
		this.onLoad()
	},

	methods: {
		onLoad() {
			GoogleAdsService.adInsights(
				this.from,
				this.to,
				this.integrationId,
				this.ad.adGroupAd.ad.id,
				(response) => {
					this.insights = response.data
				},
				() => {}
			)
		}
	}
}
</script>
