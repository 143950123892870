<template>
    <div class="container-fluid">
        <div class="row create-form">
            <div class="col-md-6 col-lg-5">
                <span class="lead">{{$t('google.posts.updateEventPost')}}</span>

                <br>
                <br>

                <card v-if="post">
                    <!-- Title -->
                    <div class="form-group" :class="{'has-error': veeErrors.has('eventTitle') }">
                        <label for="eventTitle">{{$t('google.posts.eventTitle')}}</label>

                        <span class="help-text">{{$t('google.posts.eventTitleHelp')}}</span>

                        <input type="text" class="form-control" id="eventTitle" name="eventTitle" v-model="post.event.title" :placeholder="$t('google.posts.placeholder.title')"  v-validate="'required'" :data-vv-as="$t('google.posts.eventTitle')">

                        <span v-if="veeErrors.has('eventTitle')" class="help-block">{{ veeErrors.first('eventTitle') }}</span>

                        <small v-else class="help-block pull-right" :class="{'text-danger': post.event.title.length > 58}">{{post.event.title.length}} / 58 {{$t('google.posts.characters')}}</small>
                    </div>

                    <!-- Event start date -->
                    <div class="form-group" :class="{'has-error': veeErrors.has('eventStart') }">
                        <label for="eventStart">{{$t('google.posts.eventStart')}}</label>

                        <datepicker
                            ref="eventStart"
                            :mondayFirst="true"
                            :bootstrapStyling="true"
                            :calendar-button="true"
                            calendar-button-icon="fa fa-calendar"
                            id="eventStart"
                            name="eventStart"
                            v-model="post.eventStart"
                            :format="customDateFormat"
                            v-validate="'required|date_format:Do MM YYYY'"
                            :data-vv-as="$t('google.posts.eventStart')"
                            data-vv-value-path="value">
                        </datepicker>

                        <span v-show="veeErrors.has('eventStart')" class="help-block">{{ veeErrors.first('eventStart') }}</span>
                    </div>

                    <!-- Event end date -->
                    <div class="form-group" :class="{'has-error': veeErrors.has('eventEnd') }">
                        <label for="eventEnd">{{$t('google.posts.eventEnd')}}</label>

                        <datepicker
                            ref="eventEnd"
                            :mondayFirst="true"
                            :bootstrapStyling="true"
                            :calendar-button="true"
                            calendar-button-icon="fa fa-calendar"
                            id="eventEnd"
                            name="eventEnd"
                            v-model="post.eventEnd"
                            :format="customDateFormat"
                            v-validate="'required|date_format:Do MM YYYY|after:$eventStart,true'"
                            :data-vv-as="$t('google.posts.eventEnd')">
                        </datepicker>

                        <span v-show="veeErrors.has('eventEnd')" class="help-block">{{ veeErrors.first('eventEnd') }}</span>
                    </div>

                    <!-- Summary -->
                    <div class="form-group" :class="{'has-error': veeErrors.has('summary') }">
                        <label for="summary" class="control-label">{{$t('google.posts.summary')}}</label>

                        <span class="help-text">{{$t('google.posts.summaryHelp')}}</span>

                        <textarea id="summary" name="summary" class="form-control min-input-height" :placeholder="$t('google.posts.placeholder.description')" v-model="post.summary" v-validate="'required'" :data-vv-as="$t('google.posts.summary')"></textarea>

                        <span v-if="veeErrors.has('summary')" class="help-block">{{ veeErrors.first('summary') }}</span>

                        <small v-else class="help-block pull-right" :class="{'text-danger': post.summary.length > 1500}">{{post.summary.length}} / 1500 {{$t('google.posts.characters')}}</small>
                    </div>

                    <!-- Media -->
                    <div v-if="false" class="form-group" :class="{'has-error': veeErrors.has('media') }">
                        <label for="media">{{$t('google.posts.media')}}</label>

                        <div v-if="post.media[0].googleUrl" class="media-preview">
                            <div class="btn-remove" @click="clearMedia">
                                <span class="fa fa-times fa-fw"></span>
                            </div>

                            <img :src="post.media[0].googleUrl" alt="">
                        </div>

                        <div v-else>
                            <span class="help-text">{{$t('google.posts.mediaHelp')}}</span>

                            <span class="btn btn-primary btn-file">
                                {{$t('google.posts.selectMedia')}}
                                <input type="file" id="media" name="media" accept="image/jpeg,image/png" @change="attachMedia" v-validate="'image|ext:jpg,png|min_dimensions:400,300|max_dimensions:5000,5000|min_size:10|size:2048'" :data-vv-as="$t('google.posts.media')">
                            </span>

                            <span v-show="veeErrors.has('media')" class="help-block">{{ veeErrors.first('media') }}</span>
                        </div>
                    </div>

                    <!-- Action Url -->
                    <div class="form-group" :class="{'has-error': veeErrors.has('actionUrl')}">
                        <label for="actionUrl">{{$t('google.posts.actionUrl')}}</label>
                        <input type="text" :placeholder="$t('google.posts.placeholder.link')" class="form-control" v-model="post.callToAction.url" id="actionUrl" name="actionUrl" v-validate="'required|url:true'" :data-vv-as="$t('google.posts.actionUrl')">
                        <span v-show="veeErrors.has('actionUrl')" class="help-block">{{veeErrors.first('actionUrl')}}</span>
                    </div>

                    <!-- Action type -->
                    <div class="form-group">
                        <label>{{$t('google.posts.actionType')}}</label>
                        <span class="help-text">{{$t('google.posts.actionTypeHelp')}}</span>

                        <div class="row">
                            <div class="col-md-6 col-sm-12" v-for="(label, value) in actionTypes" :key="value">
                                <div class="radio">
                                    <input :id="value" type="radio" name="actionType" v-model="post.callToAction.actionType" :value="value">
                                    <label :for="value">
                                        {{label}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <hr />

                    <transition name="slide-fade">
                        <warning v-show="failed" v-on:hide="failed = false" type="alert">
                            <p>{{$t('warnings.general.failed')}}</p>
                        </warning>
                    </transition>

                    <!-- Submit -->
                    <div class="pull-right">
                        <div class="go-back">
                            <router-link :to="{name: 'frontend.googleAds.posts.overview'}">
                                {{$t('google.posts.cancel')}}
                            </router-link>
                        </div>

                        <button type="submit" class="btn btn-success" @click="submit" :disabled="isSubmitting || veeErrors.any()">
                            <template v-if="isSubmitting">
                                <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                            </template>

                            {{$t('google.posts.save')}}
                        </button>
                    </div>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-md-6 col-lg-7 hidden-xs hidden-sm" v-if="post">
                <span class="lead">{{$t('google.posts.preview')}}</span>

                <br />
                <br />

                <post :post="post" v-if="post.event.title"></post>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .go-back {
        display: inline-block;
        color: #888;
        padding: 10px 20px;

        a {
            color: inherit;
        }
    }

    textarea {
        resize: vertical;
    }

    .help-text {
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
        color: #737373;
        font-size: 12px;
        max-width: 400px;
    }

    .media-preview {
        position: relative;

        .btn-remove {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 20px;
            text-align: center;
            height: 30px;
            width: 30px;
            background-color: #fff;
            cursor: pointer;
            border-radius: 50%;
            z-index: 15;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25), 0 5px 15px rgba(0, 0, 0, 0.05);

            &:hover {
                color: white;
                background-color: #292929;
            }
        }

        img {
            width: 100%;
            padding-top: 15px;
        }
    }

    .radio {
        margin-bottom: 10px;

        input {
            padding-left: 12px;
        }
        label {
            padding-left: 8px;
        }
    }

    .btn-file {
        position: relative;
        overflow: hidden;
        input[type=file] {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            cursor: inherit;
            display: block;
        }
    }

    .datepicker-input input {
        border-right: 1px solid #E3E3E3;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

</style>

<script>
    import Post from '@/app/google-ads/components/Post'
    import Card from '@/app/shared/components/Card'
    import Datepicker from 'vuejs-datepicker'
    import Warning from '@/app/shared/global/warnings/Warning'

    import * as LocalPostService from '@/services/google/LocalPostService'
    import MixpanelService from '@/services/mixpanel/MixpanelService'
    import hasIntegration from '@/mixins/integrations/hasIntegration'

    const moment = require('moment')

    export default {
        props: {
            name: ''
        },
        mixins: [hasIntegration],
        data() {
            return {
                post: null,

                actionTypes: {
                    'LEARN_MORE': $t('google.posts.actionTypes.learn_more'),
                    'SHOP': $t('google.posts.actionTypes.shop'),
                    'BOOK': $t('google.posts.actionTypes.book'),
                    'SIGN_UP': $t('google.posts.actionTypes.sign_up'),
                    'GET_OFFER': $t('google.posts.actionTypes.get_offer'),
                },

                isSubmitting: false,
                failed: false,

                media: {
                    googleUrl: null,
                    mediaFormat: 'PHOTO',
                    name: null,
                },
            }
        },

        computed: {
            getLocationName(){
                return this.hiIntegrations.find(integration => integration.service.name === 'Google Business Profile').data
            },
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                const payload = {
                    name: this.getPostName(this.name),
                    locationName: this.getLocationName
                }

                LocalPostService.find(payload, (response) => {
                    this.post = response.data
                    const post = this.post

                    const startDate = post.event.schedule.startDate
                    const endDate = post.event.schedule.endDate

                    post.eventStart = new moment([startDate.year, startDate.month - 1, startDate.day]).toDate()
                    post.eventEnd =  new moment([endDate.year, endDate.month - 1, endDate.day]).toDate()

                    if (post.media) {
                        this.media = {...post.media[0], sourceUrl: post.media[0].googleUrl}
                    }

                })
            },

            getPostName(name){
              const arr = name.split("/")
              return arr[2] + "/" + arr[3] + "/" + arr[4] + "/" + arr[5]
            },

            attachMedia(event) {
                this.$validator.validate('media').then(result => {
                    if (!result) {
                        return
                    }

                    const fileInput = event.target
                    const files = fileInput.files

                    if (!files.length) {
                        return;
                    }

                    const reader = new FileReader()
                    const file = files[0]

                    reader.onload = (event) => {
                        const target = event.target

                         this.media = {
                            name: file.name,
                            mediaFormat: 'PHOTO',
                            googleUrl: target.result,
                            sourceUrl: 'https://www.generaxion.com/dk/wp-content/uploads/sites/4/2020/09/generaxion-logo.png'
                        }

                        this.$set(this.post, 'media', [this.media])
                    };

                    reader.readAsDataURL(file)
                })
            },

            submit() {
                this.$validator.validateAll().then((validatorResult) => {
                    if (!validatorResult) {
                        return
                    }

                    this.failed = false
                    const post = this.post

                    post.event.schedule.startDate = {
                        year: post.eventStart.getFullYear(),
                        month: post.eventStart.getMonth() + 1,
                        day: post.eventStart.getDate()
                    }

                    post.event.schedule.endDate = {
                        year: post.eventEnd.getFullYear(),
                        month: post.eventEnd.getMonth() + 1,
                        day: post.eventEnd.getDate()
                    }

                    delete post.eventStart
                    delete post.eventEnd

                    this.isSubmitting = true

                    const payload = {
                        name: this.getPostName(this.name),
                        updateMask: 'callToAction,event,summary',
                        post: post
                    }

                    LocalPostService.update(payload, () => {
                        MixpanelService.track('Google - Posts - Update', {type: 'event'})

                        this.isSubmitting = false
                        this.$router.push({name: 'frontend.googleAds.posts.overview'})
                    }, () => {
                        this.isSubmitting = false,
                        this.failed = true
                    })
                })
            },

            customDateFormat(date) {
                return moment(date).format("Do MMM YYYY")
            },

            clearMedia() {
                this.media = {
                    googleUrl: null,
                    mediaFormat: 'PHOTO',
                    name: null,
                    sourceUrl: null
                }
                this.$set(this.post, 'media', [this.media])
            },
        },

        components: {
            Card,
            Post,
            Datepicker,
            Warning
        },
    }
</script>
