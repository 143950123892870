<template>
    <div class="edit-profile">
        <!-- Edit profile -->
        <template v-if="hasLoaded">
            <div class="row">
                <div class="col-lg-12 warning-text--control g-p--max-width">
                    <h4>{{$t('google.profile.staticWarning.headline')}}</h4>
                    <p>{{$t('google.profile.staticWarning.paragraph')}}</p>
                    <br />
                    <p>
                        <strong>{{$t('google.profile.staticWarning.olIntro')}}</strong>
                        <ol>
                            <li>{{$t('google.profile.staticWarning.listItemOne')}}</li>
                            <li>{{$t('google.profile.staticWarning.listItemTwo')}}</li>
                            <li>{{$t('google.profile.staticWarning.listItemThree')}}</li>
                        </ol>
                    </p>
                    <br />
                </div>

                <div class="col-md-6">
                    <div class="form-group" :class="{'has-error': veeErrors.has('locationName') }">
                        <label for="locationName" class="control-label">{{$t('google.profile.locationName')}}</label>
                        <input id="locationName" name="locationName" v-model="location.title" class="form-control" v-validate="'required'" :data-vv-as="$t('google.profile.locationName')">
                        <span v-show="veeErrors.has('locationName')" class="help-block">{{veeErrors.first('locationName')}}</span>
                    </div>

                    <div class="form-group" :class="{'has-error': veeErrors.has('primaryPhone') }">
                        <label for="primaryPhone" class="control-label">{{$t('google.profile.phone')}}</label>
                        <input id="primaryPhone" name="primaryPhone" v-model="location.phoneNumbers.primaryPhone" class="form-control" v-validate="'required'" :data-vv-as="$t('google.profile.phone')">
                        <span v-show="veeErrors.has('primaryPhone')" class="help-block">{{veeErrors.first('primaryPhone')}}</span>
                    </div>

                    <div class="form-group" :class="{'has-error': veeErrors.has('websiteUrl') }">
                        <label for="website" class="control-label">{{$t('google.profile.website')}}</label>
                        <input id="website" name="websiteUrl" v-model="location.websiteUri" class="form-control" v-validate="'required|url'" :data-vv-as="$t('google.profile.website')">
                        <span v-show="veeErrors.has('websiteUrl')" class="help-block">{{veeErrors.first('websiteUrl')}}</span>
                    </div>
                </div>

                <div class="col-md-6" v-if="location.storefrontAddress">
                    <div class="form-group" :class="{'has-error': veeErrors.has('address') }">
                        <label for="street" class="control-label">{{$t('google.profile.address')}}</label>
                        <input id="street" name="address" v-model="street" class="form-control" v-validate="'required'" :data-vv-as="$t('google.profile.address')">
                        <span v-show="veeErrors.has('address')" class="help-block">{{veeErrors.first('address')}}</span>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': veeErrors.has('postalCode') }">
                                <label for="postalCode" class="control-label">{{$t('google.profile.postalCode')}}</label>
                                <input id="postalCode" name="postalCode" v-model="location.storefrontAddress.postalCode" class="form-control" v-validate="'required'" :data-vv-as="$t('google.profile.postalCode')">
                                <span v-show="veeErrors.has('postalCode')" class="help-block">{{veeErrors.first('postalCode')}}</span>
                            </div>
                        </div>
                        <div class="col-md-6" :class="{'has-error': veeErrors.has('locality') }">
                            <div class="form-group">
                                <label for="locality" class="control-label">{{$t('google.profile.locality')}}</label>
                                <input id="locality" name="locality" v-model="location.storefrontAddress.locality" class="form-control" v-validate="'required'" :data-vv-as="$t('google.profile.locality')">
                                <span v-show="veeErrors.has('locality')" class="help-block">{{veeErrors.first('locality')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <button @click="update" :disabled="isSubmitting || veeErrors.any()" class="btn btn-success btn-rounded">
                            <template v-if="isSubmitting">
                                <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                            </template>
                            {{$t('google.profile.save')}}
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <!-- Loading -->
        <div v-else class="text-center">
            <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
            {{$t('google.profile.loading')}}
        </div>
    </div>
</template>

<style lang="scss" scoped>

    .warning-text--control {
        p {
            strong {
                font-size: 14px;
            }
        }


        ol {
            line-height: 20px;
            li {
                margin-bottom: 10px;
            }
        }
    }

</style>

<script>
    import * as LocationService from '@/services/google/LocationService'
    import Warning from '@/app/shared/global/warnings/Warning'
    import hasIntegration from '@/mixins/integrations/hasIntegration'

    export default {
        data() {
            return {
                location: null,
                updateStatus: null,

                hasLoaded: false,
                isSubmitting: false,
                updated: false,
            }
        },

        mixins: [hasIntegration],

        computed: {
            getLocationName(){
				if (this.isDemo) {
					return ''
				}

                return this.hiIntegrations.find(integration => integration.service.name === 'Google Business Profile').data
            },

            street: {
                get: function () {
                    if (!this.location) {
                        return null
                    }

                    const location = this.location

                    if (!location.storefrontAddress) {
                        return null
                    }

                    const address = location.storefrontAddress

                    return address.addressLines
                },

                set: function (street) {
                    if (!this.location) {
                        this.location = {}
                    }

                    if (!this.location.address) {
                        this.location.address = {}
                    }

                    this.location.storefrontAddress.addressLines = street.split(', ')
                }
            },

            locationId() {
                if (!this.location) {
                    return null
                }

                const location = this.location

                return location.name.split('/').pop()
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                this.hasLoaded = false

                const readMask = 'title,name,phoneNumbers,categories,websiteUri,regularHours,specialHours,openInfo,storefrontAddress,metadata'
                const data = {
                    locationName: this.getLocationName,
                    readMask: readMask
                }

                LocationService.location(data, (response) => {
                    this.location = response.data
                    this.hasLoaded = true
                })
            },

            update() {
                this.$validator.validateAll().then((validatorResult) => {
                    if (!validatorResult) {
                        return
                    }

                    this.$swal({
                        text: $t('google.profile.update.areYouSure'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: $t('google.profile.update.confirm'),
                        cancelButtonText: $t('google.profile.update.regret')
                    }).then(() => {
                        this.isSubmitting = true

                        const location = this.location

                        const payload = {
                            locationName: this.getLocationName,
                            updateMask: 'title,websiteUri,phoneNumbers,storefrontAddress',
                            data:  location
                        }

                        LocationService.updateHours(payload, (response) => {
                            const data = response.data
                            const awaitingApproval = data.awaitingApproval

                            let text = $t('google.profile.update.changed')
                            let type = 'success'

                            if (awaitingApproval) {
                                text = $t('google.profile.update.awaitingApproval')
                                type = 'warning'
                            }

                            this.$swal({
                                text: text,
                                type: type,
                                confirmButtonText: $t('google.profile.update.ok'),
                            })

                            this.isSubmitting = false
                            this.updated = true
                        })
                    })
                })
            }
        }
    }
</script>
