<template>
	<check-for-missing-integrations :integrations="['Google Ads']">
		<landing-page slot="no-integrations"
					  image="/images/integrations/cta-messages/adwords.png"
					  :headline="$t('google.ads.onboarding.headline')"
					  :description="$t('google.ads.onboarding.description')">
			<hr />

			<integration
				name="Google Ads"
				service="google-ads"
				:border="true"
				logo="/images/onboarding/google-ads.svg"
				:headline="$t('integrations.list.googleAds.headline')"
				:description="$t('integrations.list.googleAds.description')"
			/>

			<hr />
		</landing-page>

		<div class="page">
			<view-selector :views="views"
						   :view="view"
						   @view="setView"
			/>

			<div>
				<div v-if="showComparison"
					 class="compare-to">
					<div class="compare-to-label">{{ $t('google.ads.compare.label') }}</div>

					<div class="options">
						<div class="option"
							 @click="compareTo = 'year'"
							 :class="{ active: compareTo === 'year' }">
							<div class="option-label">{{ $t('google.ads.compare.year') }}</div>
						</div>

						<div class="option"
							 @click="compareTo = 'period'"
							 :class="{ active: compareTo === 'period' }">
							<div class="option-label">{{ $t('google.ads.compare.period') }}</div>
						</div>
					</div>
				</div>

				<networks :networks="networks"
						  :view="view"
						  :views="views"
						  :compare-to="compareTo"
				/>
			</div>

			<campaigns-table :network="network"
							 :networks="networks"
							 :view="view"
							 :views="views"
							 :view-requirements="viewRequirements"
							 @view="setView"
			/>

			<display-placements />
		</div>
	</check-for-missing-integrations>
</template>

<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 15px;

	div.compare-to {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0 0 10px 0;

		> div.compare-to-label {
			color: #000;
			font-weight: 500;
			font-size: 13px;
		}

		> div.options {
			flex: 1;

			display: flex;
			flex-wrap: wrap;
			gap: 6px;

			> div.option {
				&.active {
					> div.option-label {
						color: #000;
						border-color: #000;
					}
				}

				> div.option-label {
					cursor: pointer;
					user-select: none;

					color: #5b5b5b;
					font-weight: 400;
					padding: 4px 12px;
					border-radius: 100px;
					background-color: #fff;
					border: 2px solid #eee;

					font-size: 13px;

					&:hover {
						color: #000;
					}
				}
			}
		}
	}
}
</style>

<script>
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import Networks from '@/app/google-ads/pages/components/Networks'
import CampaignsTable from '@/app/google-ads/pages/components/campaigns-table/CampaignsTable'
import CheckForMissingIntegrations from "@/app/integrations/components/CheckForMissingIntegrations";
import LandingPage from "@/app/onboarding/components/LandingPage";
import Integration from '@/app/onboarding/components/integrations/Integration'
import DisplayPlacements from "@/app/google-ads/pages/components/DisplayPlacements";
import ViewSelector from "@/app/google-ads/pages/components/ViewSelector";
import {mapGetters} from "vuex";

const GoogleAdsService = require('@/services/google-ads/GoogleAdsService')

export default {
	mixins: [
		datepickerMixin,
		marketingMixin
	],

	data: () => ({
		view: 'default',
		network: 'google',
		networkData: {},
		compareTo: 'year',
		networks: {
			google: {
				icon: 'fab fa-google fa-fw',
				label: 'Google',
				networks: []
			},
			search: {
				icon: 'far fa-search fa-fw',
				label: 'Search',
				networks: ['SEARCH']
			},
			display: {
				icon: 'far fa-desktop fa-fw',
				label: 'Display',
				networks: ['CONTENT']
			},
			shopping: {
				icon: 'far fa-shopping-cart fa-fw',
				label: 'Shopping',
				networks: ['MIXED']
			},
			youtube: {
				icon: 'fab fa-youtube fa-fw',
				label: 'YouTube',
				networks: ['YOUTUBE']
			},
			searchPartners: {
				icon: 'far fa-users fa-fw',
				label: 'Search Partners',
				networks: ['SEARCH_PARTNERS']
			}
		},

		viewRequirements: {
			/*
			efficiency: {},
			video: {},
			display: {},
			ecommerce: {},
			conversions: {},
			competition: {}
		    */
		}
	}),

	computed: {
		showComparison() {
			return this.$store.getters['datepicker/getShowComparison']
		},

		views() {
			return {
				default: [
					{
						icon: 'far fa-mouse-pointer',
						field: 'clicks',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.clicks')
					},
					{
						icon: 'far fa-percentage',
						field: 'ctr',
						type: 'percentage-formatted',
						source: 'metrics',
						label: this.$t('google.ads.metrics.ctr')
					},
					{
						icon: 'far fa-dollar',
						field: 'averageCpc',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.cpc')
					},
					{
						icon: 'far fa-exchange',
						field: 'conversions',
						type: 'integer',
						source: 'metrics',
						label: this.$t('google.ads.metrics.conversions')
					},
					{
						icon: 'far fa-dollar',
						field: 'costPerConversion',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.costPerConversion')
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costMicros',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.spend')
					}
				],
				efficiency: [
					{
						icon: 'far fa-mouse-pointer',
						field: 'clicks',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.clicks')
					},
					{
						icon: 'far fa-percentage',
						field: 'ctr',
						type: 'percentage-formatted',
						source: 'metrics',
						label: this.$t('google.ads.metrics.ctr')
					},
					{
						icon: 'far fa-dollar',
						field: 'averageCpc',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.cpc')
					},
					{
						icon: 'far fa-eye',
						field: 'impressions',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.impressions')
					},
					{
						icon: 'far fa-arrow-right-arrow-left',
						field: 'interactions',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.interactions')
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costMicros',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.spend')
					}
				],
				video: [
					{
						icon: 'far fa-eye',
						field: 'impressions',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.impressions')
					},
					{
						icon: 'far fa-play',
						field: 'videoViews',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.videoViews')
					},
					{
						icon: 'far fa-coin',
						field: 'averageCpv',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.cpv')
					},
					{
						icon: 'far fa-coins',
						type: 'currency',
						source: 'calculation',
						label: this.$t('google.ads.metrics.cpm'),
						calculation(metrics) {
							return Number(metrics.averageCpv || 0) * 1000
						},
					},
					{
						icon: 'far fa-percentage',
						field: 'videoViewRate',
						type: 'percentage-formatted',
						source: 'metrics',
						label: this.$t('google.ads.metrics.videoViewRate')
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costMicros',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.spend')
					}
				],
				display: [
					{
						icon: 'far fa-mouse-pointer',
						field: 'clicks',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.clicks')
					},
					{
						icon: 'far fa-eye',
						field: 'impressions',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.impressions')
					},
					{
						icon: 'far fa-eye',
						field: 'averageCpm',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.cpm')
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costMicros',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.spend')
					}
				],
				ecommerce: [
					{
						icon: 'far fa-mouse-pointer',
						field: 'conversions',
						type: 'integer',
						source: 'metrics',
						label: this.$t('google.ads.metrics.orders')
					},
					{
						icon: 'far fa-sack',
						field: 'conversionsValue',
						type: 'currency',
						source: 'calculation',
						label: this.$t('google.ads.metrics.turnover'),
						calculation(metrics) {
							return Number(metrics.conversionsValue || 0) * 1000000
						},
					},
					{
						icon: 'far fa-percentage',
						type: 'percentage-formatted',
						source: 'calculation',
						label: this.$t('google.ads.metrics.conversionRate'),
						calculation(metrics) {
							const interactions = Number(metrics.interactions || 0)
							const conversions = parseInt(metrics.conversions || 0)

							return conversions / interactions
						},
					},
					{
						icon: 'far fa-handshake',
						type: 'number',
						source: 'calculation',
						label: this.$t('google.ads.metrics.roas'),
						calculation(metrics) {
							const conversionsValue = Number(metrics.conversionsValue || 0)
							const costMicros = Number(metrics.costMicros || 0)  / 1000000

							return conversionsValue / costMicros
						},
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costMicros',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.spend')
					}
				],
				conversions: [
					{
						icon: 'far fa-exchange',
						field: 'conversions',
						type: 'integer',
						source: 'metrics',
						label: this.$t('google.ads.metrics.conversions')
					},
					{
						icon: 'far fa-dollar',
						field: 'costPerConversion',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.costPerConversion')
					},
					{
						icon: 'far fa-percentage',
						type: 'percentage-formatted',
						source: 'calculation',
						label: this.$t('google.ads.metrics.conversionRate'),
						calculation(metrics) {
							const conversions = Number(metrics.conversions)
							const interactions = Number(metrics.interactions)

							return conversions / interactions
						},
					},
					/*
					{
						icon: 'far fa-eye',
						field: 'viewThroughConversions',
						type: 'number',
						source: 'metrics',
						label: this.$t('google.ads.metrics.viewThroughConversions')
					},
					*/
					{
						icon: 'far fa-sack',
						field: 'conversionsValue',
						type: 'currency',
						source: 'calculation',
						label: this.$t('google.ads.metrics.turnover'),
						calculation(metrics) {
							return Number(metrics.conversionsValue || 0) * 1000000
						}
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costMicros',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.spend')
					}
				],
				competition: [
					{
						icon: 'far fa-percentage',
						field: 'searchImpressionShare',
						type: 'percentage-formatted',
						source: 'metrics',
						label: this.$t('google.ads.metrics.searchImpressionShare')
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'costMicros',
						type: 'currency',
						source: 'metrics',
						label: this.$t('google.ads.metrics.spend')
					}
				]
			}
		},

		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		...mapGetters('ghost', {
			isDemo: 'isDemo',
		}),

		integrationId() {
			if (this.isDemo) {
				return 'demo-id'
			}

			const integrations = this.customer.integrations

			const match = integrations.find(integration => integration.service.name === "Google Ads")

			return !! match
				? match.id
				: null
		}
	},

	mounted() {
		this.pushFilters()

		this.$store.commit("datepicker/setShowComparisonToggleButton", true)
	},

	destroyed() {
		this.$store.commit("datepicker/setShowComparisonToggleButton", false)
	},

	methods: {
		setView(view) {
			this.view = view
		},

		pushFilters() {
			eventHub.$emit("topbar.filterGroups.push", {
				title: "Netværk",
				slug: "search-network",
				metrics: Object.keys(this.networks).map(key => ({
					label: this.networks[key].label,
					slug: key,
					selected: key === 'google'
				}))
			});

			eventHub.$on(
				"topbar.filterGroups.search-network.metricsChanged",
				(payload) => {
					Object.keys(payload.metrics).forEach((metricKey) => {
						const metricValue = payload.metrics[metricKey];

						if (!metricValue) {
							return;
						}

						this.network = metricKey
					});
				}
			);
		}
	},

	components: {
		ViewSelector,
		DisplayPlacements,
		Integration,
		LandingPage,
		CheckForMissingIntegrations,
		Networks,
		CampaignsTable
	}
}
</script>
